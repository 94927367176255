import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  BrandThresholdParameters,
  SortableColumnsType
} from '@app/leasing-company/brand-threshold/overview/state/overview.reducer';

export const updateBrandThresholdOverviewPaginator = createAction(
  '[LC Brand Threshold Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateBrandThresholdSorting = createAction(
  '[LC Brand Threshold Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeBrandThresholdFilters = createAction(
  '[LC Brand Threshold Overview] Store filters',
  props<{
      tableFilters: BrandThresholdParameters
  }>()
);
