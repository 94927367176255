import { Action, INIT, State, UPDATE } from '@ngrx/store';
import { merge } from 'lodash';

const INIT_ACTION: string = INIT;
const UPDATE_ACTION: string = UPDATE;

/**
 * This reducer is designed to handle merging state with rehydrated state retrieved
 * from local storage. It takes the current state, rehydrated state, and an action as
 * input, and returns the merged state if necessary.
 *
 * @param {State<unknown>} state - The current state of the application.
 * @param {State<unknown>} rehydratedState - The rehydrated state retrieved from local storage.
 * @param {Action} action - The action dispatched in the application.
 * @returns {State<unknown>} The merged state, or the original state if merging is not applicable.
 */
export function rsLocalStorageMergeReducer(
    state: State<unknown>,
    rehydratedState: State<unknown>,
    action: Action,
): State<unknown> {
  const shouldRehydrate = [INIT_ACTION, UPDATE_ACTION].includes(action.type) && rehydratedState;

  return shouldRehydrate ? tryMerge(state, rehydratedState) : state;
}

function tryMerge(state: State<unknown>, rehydratedState: State<unknown>): State<unknown> {
  try {
    return merge(state, rehydratedState);
  } catch (exception) {
    return state;
  }
}
