import { createAction, props } from '@ngrx/store';
import { DoubleMaintenanceCreate } from '@shared/models/main-intervals/double-maintenance/double-maintenance';

export const createDoubleMaintenance = createAction(
  '[Double Maintenance Add] Add Double Maintenance',
  props<{ doubleMaintenance: DoubleMaintenanceCreate }>()
);

export const createDoubleMaintenanceSuccess = createAction(
  '[Double Maintenance Add] Add Double Maintenance Success',
  props<{ doubleMaintenanceId: number }>()
);

