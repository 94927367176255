import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  DisapprovalReasonThresholdParameters,
  SortableColumnsType
} from '@app/leasing-company/disapproval-reason-threshold/overview/state/overview.reducer';

export const updateDisapprovalReasonThresholdOverviewPaginator = createAction(
  '[LC Disapproval Reason Threshold Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateDisapprovalReasonThresholdSorting = createAction(
  '[LC Disapproval Reason Threshold Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeDisapprovalReasonThresholdFilters = createAction(
  '[LC DisapprovalReason Threshold Overview] Store filters',
  props<{
    tableFilters: DisapprovalReasonThresholdParameters
  }>()
);
