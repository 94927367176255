import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { storeRentalClassFilters, updateRentalClassOverviewPaginator, updateRentalClassSorting } from '@app/leasing-company/rental-classes/overview/state/overview.actions';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewRentalClassFeatureKey = 'overview-leasing-company-rental-class';

export const sortableColumns = ['code', 'maxPrice', 'active'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface RentalClassOverviewState {
  tableFilters: RentalClassParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface RentalClassParameters {
  codeFrom?: string;
  codeTo?: string;
  description?: string;
  active?: boolean;
}

export const rentalClassInitialState: RentalClassOverviewState = {
  tableOptions: {
    sort: {
      active: 'code',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {}
};

export const rentalClassReducer = createReducer(
  rentalClassInitialState,

  on(updateRentalClassOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent
      }
    };
  }),

  on(updateRentalClassSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort
      }
    }
  }),

  on(storeRentalClassFilters, (state, { tableFilters }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters
      }
    };
  })
);

function localStorageSyncReducer(reducer: ActionReducer<RentalClassOverviewState>): ActionReducer<RentalClassOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_rental_class_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const rentalClassMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
