import { createAction, props } from '@ngrx/store';
import { BrandActivityMappingParameters } from '@shared/models/brand-activity-mapping/brand-activity-mapping-parameters';

export const updateBrandActivityMappingOverviewPaginator = createAction(
  '[Overview] Update brand activity mapping paginator',
  props<{
    resultsPerPage: number;
    page: number;
  }>()
);

export const storeBrandActivityMappingFilters = createAction(
  '[Overview] Store brand activity mapping filters',
  props<{
    filterParams: BrandActivityMappingParameters
  }>()
);
