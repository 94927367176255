import { createAction } from '@ngrx/store';

export const showButtonSpinner = createAction(
  '[Spinner] Show button spinner'
);

export const hideButtonSpinner = createAction(
  '[Spinner] Hide button spinner'
)

