import { createAction, props } from '@ngrx/store';
import { ActivityGroupFilterParameters } from '@shared/models/vehicle-types/filter-parameters';

export const updateVehicleTypesOverviewFilter = createAction(
  '[Overview] Update VehicleTypesOverview Filter',
  props<{ params: ActivityGroupFilterParameters }>()
);

export const updateVehicleTypesOverviewPaginator = createAction(
  '[Overview] Update VehicleTypesOverview Paginator',
  props<{
    resultsPerPage: number;
    page: number;
  }>()
);

export const updateVehicleTypesOverviewSort = createAction(
  '[Overview] Update VehicleTypesOverview Sort',
  props<{
    sortDirection: string;
    sortBy: string;
  }>()
);




