import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  RentalClassParameters,
  SortableColumnsType
} from '@app/leasing-company/rental-classes/overview/state/overview.reducer';

export const updateRentalClassOverviewPaginator = createAction(
  '[LC Rental Class Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateRentalClassSorting = createAction(
  '[LC Rental Class Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeRentalClassFilters = createAction(
  '[LC Rental Class Overview] Store filters',
  props<{
      tableFilters: RentalClassParameters
  }>()
);
