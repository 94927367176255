import { isPlainObject, keys, values } from 'lodash';

/**
 * Counts the number of filters in a given object based on specified keys and key combinations.
 * Primarily used in reducers. See unit tests for specific features/behavior.
 *
 * How types are counted:
 * - booleans: only when true
 * - strings: only when not empty
 * - arrays: only when not empty
 * - objects: only when there's at least one entry that isn't null, undefined, empty string, nor false
 *
 * @template FiltersObject - The type of the filters object.
 * @param {FiltersObject} filtersObject - The object containing the filters.
 * @param {Array<Array<keyof FiltersObject> | keyof FiltersObject>} [keysToCount=keys(filtersObject)] - The keys or key combinations to count.
 *   Default value: Every key in the filtersObject.
 * @returns {number} The total count of filters. It sums up the counts of individual keys and key combinations provided.
 *
 * @example
 * const filters = {
 *   active: true,
 *   company: 1234,
 *   dateFrom: '23/04/2024',
 *   dateTo: '23/04/2024',
 * };
 *
 * const singleKeyCount = rsCountFilters(filters, [
 *   'active',
 *   'company',
 * ]);
 * // Returns 2 (counts 'active' and 'company')
 *
 * const comboCount = rsCountFilters(filters, [
 *   'active',
 *   ['dateFrom', 'dateTo'],
 * ]);
 * // Returns 2 (counts 'active', and 'dateFrom' || 'dateTo')
 */
export function rsCountFilters<FiltersObject extends object>(
    filtersObject: FiltersObject,
    keysToCount: Array<Array<keyof FiltersObject> | keyof FiltersObject> = keys(filtersObject) as Array<keyof FiltersObject>,
): number {
  const singleKeys = keysToCount.filter(key => !Array.isArray(key)) as Array<keyof FiltersObject>;
  const keyCombos = keysToCount.filter(key => Array.isArray(key)) as Array<Array<keyof FiltersObject>>;

  const singleKeysCount = countSingleKeys(filtersObject, singleKeys);
  const keyCombosCount = countCombos(filtersObject, keyCombos);

  return singleKeysCount + keyCombosCount;
}

function countCombos<FiltersObject extends object>(filtersObject: FiltersObject, keyCombosToCount: Array<Array<keyof FiltersObject>>): number {
  return keyCombosToCount
    .filter(combo => combo.some(key => shouldCount(filtersObject, key)))
    .length;
}

function countSingleKeys<FiltersObject extends object>(filtersObject: FiltersObject, singleKeysToCount: Array<keyof FiltersObject>): number {
  return singleKeysToCount
    .filter(key => shouldCount(filtersObject, key))
    .length;
}

function shouldCount<FiltersObject extends object>(filtersObject: FiltersObject, key: keyof FiltersObject): boolean {
  const filterValue = filtersObject[key];

  if (Array.isArray(filterValue)) {
    return isNotEmptyArray(filterValue as Array<unknown>);
  }
  if (isPlainObject(filterValue)) {
    return objectHasValues(filterValue as object);
  }

  return isNotEmptyValue(filterValue);
}

function objectHasValues(objectValue: object): boolean {
  return values(objectValue).some(isNotEmptyValue);
}

function isNotEmptyArray(value: Array<unknown>): boolean {
  return value.length !== 0;
}

function isNotEmptyValue(value?: unknown): boolean {
  return !(value === undefined || value === null || value === '' || value === false);
}
