import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserType } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly currentUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    JSON.parse(localStorage.getItem('currentUser') as string) as User | null
  );

  constructor(private readonly http: HttpClient) {
  }

  public getAvailableUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiUrl + '/auth/user')
      .pipe(
        map(users => users.map(user => omit(user, 'email')))
      );
  }

  public setCurrentUser(user: User | null): void {
    this.currentUser$.next(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public readonly getCurrentUser = (): BehaviorSubject<User | null> => {
    return this.currentUser$;
  }

  public getUserRole(): string {
    // eslint-disable-next-line rxjs/no-subject-value
    const userRole = this.currentUser$.getValue()?.userRole;
    return userRole ? userRole : '';
  }

  public hasAnyLeasingCompanyUserRole(): boolean {
    switch (this.getUserRole()) {
      case 'LEASING_COMPANY':
      case 'LEASING_COMPANY_WITH_AUTHORIZATION':
      case 'LEASING_COMPANY_MANAGER':
        return true;
      default:
        return false;
    }
  }

  public hasLeasingCompanyManagerUserRole(): boolean {
    return this.getUserRole() === 'LEASING_COMPANY_MANAGER';
  }

  public hasLeasingCompanyWithAuthorizationUser(): boolean {
    return this.getUserRole() === 'LEASING_COMPANY_WITH_AUTHORIZATION';
  }

  public hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole(): boolean {
    return this.hasLeasingCompanyManagerUserRole() || this.hasLeasingCompanyWithAuthorizationUser();
  }

  public isAdminUser(): boolean {
    return this.getUserRole() === 'ADMIN';
  }

  public isSupplierUser(): boolean {
    return this.getUserRole() === 'SUPPLIER' || this.getUserRole() === 'DMS';
  }

  public hasAccessToManagement(): boolean {
    return this.hasLeasingCompanyManagerOrLeasingCompanyWithAuthorizationUserRole() || this.isAdminUser();
  }

  public getDefaultRedirectURL(): string {
    if (this.hasAnyLeasingCompanyUserRole()) {
      return '/leasing-company';
    }
    if (this.isAdminUser()) {
      return '/admin';
    }
    if (this.isSupplierUser()) {
      return '/supplier';
    }

    return '/rs-number';
  }

  public getUserType(): UserType | null {
    if (this.hasAnyLeasingCompanyUserRole()) {
      return 'LEASING_COMPANY';
    }
    if (this.isAdminUser()) {
      return 'ADMIN';
    }
    if (this.isSupplierUser()) {
      return 'SUPPLIER';
    }

    return null;
  }
}
