import { createAction, props } from '@ngrx/store';
import { FebiacModelCode } from '../../../shared/models/febiac/febiac-model-code';

export const loadFebiacModels = createAction(
  '[FebiacModel] Load FebiacModels',
  props<{ code: string }>()
);

export const loadFebiacModelsSuccess = createAction(
  '[FebiacModel] Load FebiacModels Success',
  props<{ models: FebiacModelCode[] }>()
);

