import { Action, createReducer, on } from '@ngrx/store';
import { ActivityFuelParameters } from '@shared/models/activities/fuel/activity-fuel-parameters';
import { updateActivitiesFuelOverviewFilter, updateActivitiesFuelOverviewPaginator, updateActivitiesFuelOverviewSort } from '@app/admin/activities/fuel/overview/state/overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';

export const activitiesFuelOverviewFeatureKey = 'activitiesFuel';

export interface ActivitiesFuelOverviewState {
  params: ActivityFuelParameters;
  numberOfFilters: number
}

export const activitiesFuelOverviewState: ActivitiesFuelOverviewState = {
  params: {
    page: 1,
    resultsPerPage: 10,
    sortBy: 'ACTIVITY_CODE',
    sortDirection: 'ASC'
  },
  numberOfFilters: 0
};

export const _reducer = createReducer(
  activitiesFuelOverviewState,
  on(updateActivitiesFuelOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'activityCodeFrom',
      'activityCodeTo',
      'activityDescription',
    ]);

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params.page = 1;
      draft.numberOfFilters = numberOfFilters;
    });
  }),
  on(updateActivitiesFuelOverviewPaginator, (state, {
    resultsPerPage, page
  }) => {
    const newPage = state.params?.resultsPerPage != resultsPerPage ? 1 : page;
    return { ...state, params: { ...state.params, resultsPerPage, page: newPage } };
  }),
  on(updateActivitiesFuelOverviewSort, (state, {
    sortDirection, sortBy
  }) => {
    return { ...state, params: { ...state.params, sortBy, sortDirection } };
  })
);

export function activitiesFuelOverviewReducer(
    state: ActivitiesFuelOverviewState,
    action: Action
): ActivitiesFuelOverviewState {
  return _reducer(state, action);
}
