import { createAction, props } from '@ngrx/store';
import { CustomCommentsFilterParameters } from '@shared/models/custom-comments/filter-parameters';

export const updateCustomCommentsOverviewFilter = createAction(
  '[Overview] Update CustomCommentsOverview Filter',
  props<{ params: CustomCommentsFilterParameters }>()
);

export const updateCustomCommentsOverviewPaginator = createAction(
  '[Overview] Update CustomCommentsOverview Paginator',
  props<{
    resultsPerPage: number;
    page: number;
  }>()
);

export const updateCustomCommentsOverviewSort = createAction(
  '[Overview] Update CustomCommentsOverview Sort',
  props<{
    sortDirection: string;
    sortBy: string;
  }>()
);




