import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { febiacModelReducer, FebiacModelState } from './febiac-model/febiac-model.reducer';
import { showSpinnerReducer, ShowSpinnerState } from './show-spinner/show-spinner.reducer';
import { resetState } from './core.actions';

export const coreFeatureKey = 'core';

export interface CoreState {
    febiacModel: FebiacModelState;
    showSpinner: ShowSpinnerState;
}

export const initialState: Partial<CoreState> = {};

export const coreReducer: ActionReducerMap<CoreState> = {
  febiacModel: febiacModelReducer as ActionReducer<FebiacModelState>,
  showSpinner: showSpinnerReducer as ActionReducer<ShowSpinnerState>,
};

export function clearState(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return (state: CoreState | undefined, action: Action): CoreState => {
    if (action.type === resetState.type) {
      return reducer({
        febiacModel: state!.febiacModel,
        showSpinner: state!.showSpinner,
      }, action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<CoreState>[] = [clearState];
