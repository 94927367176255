<div class="container">
  <div class="row">
    <div class="col text-center">
      <br />
      <br />
      <br />
      <a
        data-cy="home-link"
        routerLink="/"
      >
        <mat-icon>contact_support</mat-icon>
        <br />
        <br />
        <br />
        {{ 'PAGE_NOT_FOUND' | translate }}
      </a>
    </div>
  </div>
</div>