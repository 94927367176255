import { Action, createReducer, on } from '@ngrx/store';
import { updateVehicleTypesOverviewFilter, updateVehicleTypesOverviewPaginator, updateVehicleTypesOverviewSort } from '@app/admin/vehicle-types/overview/state/overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { LegacyFilterParameters } from '@shared/models/filter-parameters';
import { produce } from 'immer';

export const vehicleTypesOverviewFeatureKey = 'vehicle-types-overview';

export interface VehicleTypesOverviewState {
  params: {
    vehicleTypeCodeFrom?: string;
    vehicleTypeCodeTo?: string;
    vehicleTypeDescription?: string;
    bike?: boolean | '';
  } & LegacyFilterParameters;
  numberOfFilters: number;
}

export const vehicleTypesOverviewInitialState: VehicleTypesOverviewState = {
  params: {
    page: 1,
    resultsPerPage: 10,
    sortBy: 'VEHICLE_TYPE_CODE',
    sortDirection: 'ASC'
  },
  numberOfFilters: 0
};

const _reducer = createReducer(
  vehicleTypesOverviewInitialState,
  on(updateVehicleTypesOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'vehicleTypeCodeFrom',
      'vehicleTypeCodeTo',
      'vehicleTypeDescription',
    ]);
    const bikeFilterCount = params.bike === '' ? 0 : 1;

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params.page = 1;
      draft.numberOfFilters = numberOfFilters + bikeFilterCount;
    });
  }),
  on(updateVehicleTypesOverviewPaginator, (state, {
    resultsPerPage, page
  }) => {
    const newPage = state.params?.resultsPerPage != resultsPerPage ? 1 : page;
    return { ...state, params: { ...state.params, resultsPerPage, page: newPage } };
  }),
  on(updateVehicleTypesOverviewSort, (state, {
    sortDirection, sortBy
  }) => {
    return { ...state, params: { ...state.params, sortBy, sortDirection } };
  })
);

export function overviewReducer(
    state: VehicleTypesOverviewState,
    action: Action
): VehicleTypesOverviewState {
  return _reducer(state, action);
}
