import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';

const defaultOptions = {
  urlToRedirect: '/rs-number',
};

export function isAdminGuard({ urlToRedirect = defaultOptions.urlToRedirect } = defaultOptions): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return userService.isAdminUser() ? true: router.parseUrl(urlToRedirect);
  }
}
