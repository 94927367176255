import { Action, createReducer, on } from '@ngrx/store';
import { ActivityDefectTaskWarrantyParameters } from '@shared/models/activity-defect-task-warranty-parameters';
import { updateActivitiesDefectTaskWarrantyOverviewFilter, updateActivitiesDefectTaskWarrantyOverviewPaginator, updateActivitiesDefectTaskWarrantyOverviewSort } from '@app/admin/activities/defect-task-warranty/overview/state/overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';

export const activitiesDefectTaskWarrantyOverviewFeatureKey = 'activitiesDefectTaskWarrantyOverview';

export interface ActivitiesDefectTaskWarrantyOverviewState {
  params: ActivityDefectTaskWarrantyParameters;
  numberOfFilters: number
}

export const activitiesDefectTaskWarrantyOverviewState: ActivitiesDefectTaskWarrantyOverviewState = {
  params: {
    _page: 1,
    _pageSize: 10,
    _sortBy: 'ACTIVITY_CODE',
    _sortDirection: 'ASC'
  },
  numberOfFilters: 0
};

export const _reducer = createReducer(
  activitiesDefectTaskWarrantyOverviewState,
  on(updateActivitiesDefectTaskWarrantyOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'activityCodeFrom',
      'activityCodeTo',
      'activityDescription',
    ]);

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params._page = 1;
      draft.numberOfFilters = numberOfFilters;
    });
  }),
  on(updateActivitiesDefectTaskWarrantyOverviewPaginator, (state, {
    _pageSize, _page
  }) => {
    const newPage = state.params?._pageSize != _pageSize ? 1 : _page;
    return { ...state, params: { ...state.params, _pageSize, _page: newPage } };
  }),
  on(updateActivitiesDefectTaskWarrantyOverviewSort, (state, {
    _sortDirection, _sortBy
  }) => {
    return { ...state, params: { ...state.params, _sortBy, _sortDirection } };
  })
);

export function activitiesDefectTaskWarrantyOverviewReducer(
    state: ActivitiesDefectTaskWarrantyOverviewState,
    action: Action
): ActivitiesDefectTaskWarrantyOverviewState {
  return _reducer(state, action);
}
