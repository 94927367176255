<section class="mb-50px">
  <div class="row align-items-center mb-2">
    <div class="col-6 d-flex">
      <ng-content select="h2"></ng-content>
      <ng-content select="span"></ng-content>
    </div>

    <div
      *ngIf="!editable && displayEditButton"
      class="col-6 text-end"
    >
      <button
        (click)="onEditable()"
        color="accent"
        mat-icon-button
        data-testid="edit-button"
      >
        <mat-icon class="yellow">create</mat-icon>
      </button>
    </div>
  </div>

  <div
    class="rs-card"
    id="card"
  >
    <ng-content></ng-content>
  </div>
</section>