import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { updateCustomCommentsOverviewFilter, updateCustomCommentsOverviewPaginator, updateCustomCommentsOverviewSort } from './custom-comments-overview.actions';
import { CustomCommentsFilterParameters } from '@shared/models/custom-comments/filter-parameters';
import { localStorageSync } from 'ngrx-store-localstorage';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';

export const customCommentsOverviewFeatureKey = 'customCommentsOverview';

export interface CustomCommentsOverviewState {
  params: CustomCommentsFilterParameters;
  numberOfFilters: number;
}

export const customCommentsOverviewInitialState: CustomCommentsOverviewState = {
  params: {
    page: 1,
    resultsPerPage: 10,
    sortBy: 'KEYWORD',
    sortDirection: 'ASC',

    keyword: '',
    active: '',
  },
  numberOfFilters: 0
};

export const customCommentsOverviewReducer = createReducer(
  customCommentsOverviewInitialState,
  on(updateCustomCommentsOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, ['keyword',]);
    const activeFilterCount = params.active === '' ? 0 : 1;

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params.page = 1;
      draft.numberOfFilters = numberOfFilters + activeFilterCount;
    });
  }),
  on(updateCustomCommentsOverviewPaginator, (state, {
    resultsPerPage, page
  }) => {
    const newPage = state.params?.resultsPerPage != resultsPerPage ? 1 : page;
    return { ...state, params: { ...state.params, resultsPerPage, page: newPage } };
  }),
  on(updateCustomCommentsOverviewSort, (state, {
    sortDirection, sortBy
  }) => {
    return { ...state, params: { ...state.params, sortBy, sortDirection } };
  })
);

function localStorageSyncReducer(reducer: ActionReducer<CustomCommentsOverviewState>): ActionReducer<CustomCommentsOverviewState> {
  return localStorageSync({
    keys: [
      'params',
      'numberOfFilters',
    ],
    storageKeySerializer: key => 'leasing_company_custom_comments_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const customCommentsMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
