import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { DisapprovalReasonParameters } from '@shared/models/disapproval-reason/leasing-company/disapproval-reason-parameters';
import { storeDisapprovalReasonFilters, updateDisapprovalReasonOverviewPaginator, updateDisapprovalReasonSorting } from '@app/leasing-company/disapproval-reason/overview/overview/state/overview.actions';
import { TableOptions } from '@lib/rs-ngx';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewDisapprovalReasonFeatureKey = 'overview-disapproval-reason';

export const sortableColumns = ['code', 'description'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface DisapprovalReasonOverviewState {
  tableFilters: DisapprovalReasonParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export const disapprovalReasonInitialState: DisapprovalReasonOverviewState = {
  tableOptions: {
    sort: {
      active: 'code',
      direction: 'asc',
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
    },
  },
  tableFilters: {},
};

export const disapprovalReasonReducer = createReducer(
  disapprovalReasonInitialState,

  on(updateDisapprovalReasonOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent,
      },
    };
  }),

  on(updateDisapprovalReasonSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort,
      },
    };
  }),

  on(storeDisapprovalReasonFilters, (state, { tableFilters }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters,
      },
    };
  }),
);

function localStorageSyncReducer(reducer: ActionReducer<DisapprovalReasonOverviewState>): ActionReducer<DisapprovalReasonOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_disapproval_reason_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const disapprovalReasonMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
