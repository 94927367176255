import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeTyreBrandDiscountSupplierGroupFilters, updateTyreBrandDiscountSupplierGroupOverviewPaginator, updateTyreBrandDiscountSupplierGroupSorting } from '@app/leasing-company/tyre-brand-discount/supplier-groups/overview/state/overview.actions';
import { OverviewParams, TyreBrandDiscountSupplierGroupParameters } from '@shared/models/tyre-brand-discount/supplier-group/tyre-brand-discount-parameters';
import { produce } from 'immer';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewTyreBrandDiscountSupplierGroupFeatureKey = 'overview-leasing-company-tyre-brand-discount-supplier-group';

export const sortableColumns = ['brand', 'supplierGroupDeviations'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface TyreBrandDiscountSupplierGroupOverviewState {
    tableFilters: TyreBrandDiscountSupplierGroupParameters;
    paginationParams: OverviewParams
}

export const tyreBrandDiscountSupplierGroupInitialState: TyreBrandDiscountSupplierGroupOverviewState = {
  paginationParams: {
    _page: 1,
    _pageSize: 10,
    _sortBy: 'TYRE_BRAND',
    _sortDirection: 'ASC'
  },
  tableFilters: {
    supplierGroupId: null,
    tyreVehicleTypeCode: null,
    tyreBrandId: null
  }
};

export const tyreBrandDiscountSupplierGroupReducer = createReducer(
  tyreBrandDiscountSupplierGroupInitialState,

  on(updateTyreBrandDiscountSupplierGroupOverviewPaginator, (state, {
    resultsPerPage, page
  }) =>
    produce(state, draftState => {
      draftState.paginationParams._pageSize = resultsPerPage;
      draftState.paginationParams._page = page;
    })),
  on(updateTyreBrandDiscountSupplierGroupSorting, (state, {
    sortDirection, sortBy
  }) =>
    produce(state, draftState => {
      draftState.paginationParams._sortDirection = sortDirection;
      draftState.paginationParams._sortBy = sortBy;
    })),
  on(storeTyreBrandDiscountSupplierGroupFilters, (state, { tableFilters }) =>
    produce(state, draftState => {
      Object.assign(draftState.tableFilters, tableFilters);
      draftState.paginationParams._page = 1;
    }))
);

function localStorageSyncReducer(reducer: ActionReducer<TyreBrandDiscountSupplierGroupOverviewState>): ActionReducer<TyreBrandDiscountSupplierGroupOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_tyre_brand_discount_supplier_group_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const tyreBrandDiscountSupplierGroupMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
