import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { isArray } from 'lodash';


/**
 * @description Sorts an array of objects alphabetically based on a specified property.
 *
 * @param property - The property name by which to sort the objects.
 *
 * @returns An RxJS operator that can be used with the `pipe` method on an Observable.
 *
 * Example of usage:
 *
 * export class YourComponent {
 *   febiacModelList$: Observable<FebiacModelCode[]>;
 *   febiacBrandList$: Observable<FebiacBrand[]>;
 *
 *   constructor(private apiService: ApiService) {
 *     // Sort models by description
 *     this.febiacModelList$ = this.apiService.getModels().pipe(
 *       sortByProperty('description')
 *     );
 *
 *     // Sort brands by description
 *     this.febiacBrandList$ = this.apiService.getBrands().pipe(
 *       sortByProperty('description')
 *     );
 *   }
 * }
 *
 * With this custom RxJS operator, you can sort an array of objects in your Observable streams based on a specified property name in a clean and reusable way.
 */

export function rsSortByProperty<T, U>(property: keyof T): OperatorFunction<T[] | U, T[] | U> {
  return (source$) => source$
    .pipe(
      map((data) => {
        if (!isArray(data)) {
          return data;
        }

        return data
          .slice()
          .sort((valueA, valueB) => {
            const propA = String(valueA[property]).toUpperCase();
            const propB = String(valueB[property]).toUpperCase();

            return propA.localeCompare(propB);
          });
      })
    );
}
