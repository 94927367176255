import { Action, createReducer, on } from '@ngrx/store';
import { hideButtonSpinner, showButtonSpinner } from './show-spinner.actions';

export const showSpinnerFeatureKey = 'showSpinner';

export interface ShowSpinnerState {
  showButtonSpinner: boolean;
}

export const initialState: Partial<ShowSpinnerState> = {};

export const _reducer = createReducer(
  initialState,

  on(showButtonSpinner, (state) => {
    return {
      ...state,
      showButtonSpinner: true
    }
  }),
  on(hideButtonSpinner, (state) => {
    return {
      ...state,
      showButtonSpinner: false
    }
  })
);

export function showSpinnerReducer(state: ShowSpinnerState, action: Action): Partial<ShowSpinnerState> {
  return _reducer(state, action);
}

