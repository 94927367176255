import { Directive, effect, ElementRef, inject, input, model, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * @directive rsSpinnerButton
 * @description A directive that adds a visual cue (spinner or styling) to a button element, indicating busy state.
 *  * It can be triggered manually or automatically based on an observable.
 */
@Directive({
  selector: 'button[rsSpinnerButtonShow], button[rsSpinnerButtonShowAsyncOnClick], button[rsButtonSpinner]',
  exportAs: 'rsButtonSpinner',
  standalone: true
})
export class RsSpinnerButtonDirective implements OnInit {
  /** @description **Directive:** rsSpinnerButtonShow
   * @description Manually controls the spinner display.
   *  * When set to `true`, the directive will show the spinner or apply the 'rs-button-spinning' class (depending on rsSpinnerButtonType).
   *  * When set to `false` (default), the spinner will be hidden and the class will be removed.
   *  * Check rsSpinnerButtonShowAsyncOnClick for async use.
   *
   *   @use
   *   ```html
   *   <button [rsSpinnerButtonShow]="Boolean">Click me</button>
   *   ```
   */
  public readonly rsSpinnerButtonShow = model<boolean>(false);

  /** @description **Input:** rsSpinnerButtonType
   * @description An input property to define the spinner type ('border' or 'spinner'). Defaults to 'border'.
   *  * **Applicable only when rsSpinnerButtonShow or rsSpinnerButtonShowAsyncOnClick is used.**
   *  *  - 'border': Applies a CSS border styling to the button, signifying a busy state.
   *  *  - 'spinner': Appends a Material Design Progress Spinner (https://material.angular.io/components/progress-spinner) to the button.
   */
  protected readonly rsSpinnerButtonType = input<'border' | 'spinner'>('border');

  /**
   * @description **Directive:** rsSpinnerButtonShowAsyncOnClick
   * @description Controls showing the spinner asynchronously based on an observable emitted ***UPON A CLICK EVENT***.
   *  * When provided, the directive will show the spinner or apply the 'rs-button-spinning' class (depending on rsSpinnerButtonType)
   *  * While the observable is emitting values. Once the observable completes, the spinner will be hidden and the class will be removed.
   *  * The directive will only subscribe to the observable once.
   *
   *  @use
   *   ```html
   *   <button [rsSpinnerButtonShowAsyncOnClick]="myObservable">Click me (spinner shows asynchronously)</button>
   *   ```
   */
  protected readonly rsSpinnerButtonShowAsyncOnClick = input<Observable<unknown>>();

  private readonly viewContainerRef  = inject(ViewContainerRef);
  private readonly hostButtonElement: HTMLButtonElement = inject(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);

  public constructor() {
    effect(() => {
      this.showSpinner(this.rsSpinnerButtonShow());
    });
  }

  public ngOnInit(): void {
    this.setupSpinner();
    this.shouldAttachOnClickListener();
  }

  private showSpinner(isSpinning: boolean): void {
    this.toggleRendererClass(isSpinning, 'rs-button-spinning');
    this.toggleRendererClass(isSpinning, 'rs-button-disabled');
  }

  private setupSpinner(): void {
    if (this.rsSpinnerButtonType() === 'spinner') { this.appendMatSpinner(); }
    this.renderer.addClass(this.hostButtonElement, 'rs-button-spinner');
    this.renderer.addClass(this.hostButtonElement, `rs-button-spinner-type--${this.rsSpinnerButtonType()}`);
  }

  private shouldAttachOnClickListener(): void {
    if(this.rsSpinnerButtonShowAsyncOnClick()) {
      this.renderer.listen(this.hostButtonElement, 'click', () => {
        this.rsSpinnerButtonShow.set(true);

        this.rsSpinnerButtonShowAsyncOnClick()
          ?.pipe(
            take(1),
            finalize(() => this.rsSpinnerButtonShow.set(false))
          )?.subscribe();
      });
    }
  }

  private toggleRendererClass(condition: boolean, className: string): void {
    this.renderer[condition ? 'addClass' : 'removeClass'](this.hostButtonElement, className);
  }

  private appendMatSpinner(): void {
    const componentRef = this.viewContainerRef.createComponent(MatProgressSpinner);
    const spinner: MatProgressSpinner = componentRef.instance;
    const spinnerContainer: HTMLDivElement = this.renderer.createElement('div');

    // Define and setup spinner
    spinner.strokeWidth = 2;
    spinner.diameter = 20;
    spinner.mode = 'indeterminate';

    // Add classes
    this.renderer.addClass(spinnerContainer, 'spinner-container');

    // Append container spinner
    this.renderer.appendChild(this.hostButtonElement, spinnerContainer);
    this.renderer.appendChild(spinnerContainer, spinner._elementRef.nativeElement); // Append the spinner
  }
}
