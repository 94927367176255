import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { UserService } from '@core/services/user.service';
import { inject } from '@angular/core';

export function oldUiGuard(): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    if ( !environment?.legacyUiUrl ) {
      return router.parseUrl('/rs-number');
    }

    let redirectUrl = environment.legacyUiUrl;
    const userLoginString = userService.getCurrentUser().getValue()?.loginString;

    if (userLoginString) {
      redirectUrl += `&belnumber=${userLoginString}`;
    }

    window.location.href = redirectUrl;
    return false;
  }
}
