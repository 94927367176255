import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { storeLeasingContractGroupFilters, updateLeasingContractGroupOverviewPaginator, updateLeasingContractGroupSorting } from '@app/leasing-company/leasing-contract-group/overview/state/overview.actions';
import { produce } from 'immer';

export const overviewLeasingContractGroupFeatureKey = 'overview-leasing-company-leasing-contract-group';

export const sortableColumns = ['code'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface LeasingContractGroupOverviewState {
  tableFilters: LeasingContractGroupParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface LeasingContractGroupParameters {
  searchTerm?: string;
}

export const leasingContractGroupInitialState: LeasingContractGroupOverviewState = {
  tableOptions: {
    sort: {
      active: 'code',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {}
};

export const leasingContractGroupReducer = createReducer(
  leasingContractGroupInitialState,

  on(updateLeasingContractGroupOverviewPaginator, (state, { pageEvent }) => {
    return produce(state, draft => {
      draft.tableOptions.pageEvent = pageEvent;
    });
  }),

  on(updateLeasingContractGroupSorting, (state, { sort }) => {
    return produce(state, draft => {
      draft.tableOptions.sort = sort;
    });
  }),

  on(storeLeasingContractGroupFilters, (state, { tableFilters }) => {
    return produce(state, draft => {
      draft.tableFilters = tableFilters;
    });
  })
);

function localStorageSyncReducer(reducer: ActionReducer<LeasingContractGroupOverviewState>): ActionReducer<LeasingContractGroupOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: (key) => 'leasing_company_leasing_contract_group_overview_' + key,
    rehydrate: true,
  })(reducer);
}

export const leasingContractGroupMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
