import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RsMessagesHandlerService } from '@lib/rs-ngx';
import { showHttpRequestSuccessMessage } from './core.actions';


@Injectable()
export class CoreEffects {

  public httpRequestSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(showHttpRequestSuccessMessage),
        tap(() => {
          this.rsMessageService.showSuccessMsg();
        })
      );
    },
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly rsMessageService: RsMessagesHandlerService) {
  }
}
