<mat-form-field
  (click)="yearMonthPicker.open()"
  class="full-width rs-datepicker"
  rsAddClearFormFieldButton
>
  <mat-label>
    {{ label }}
  </mat-label>
  <input
    [disabled]="disabled"
    [matDatepicker]="yearMonthPicker"
    [value]="value"
    matInput
    readonly
    type="text"
  />

  <mat-datepicker-toggle
    [for]="yearMonthPicker"
    matSuffix
  >
  </mat-datepicker-toggle>

  <mat-datepicker
    #yearMonthPicker
    (monthSelected)="chosenMonthHandler($event, yearMonthPicker)"
    (yearSelected)="chosenYearHandler($event)"
    startView="multi-year"
  >
  </mat-datepicker>
</mat-form-field>