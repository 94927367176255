import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import Utils from '@app/utils';
import { TranslateService } from '@ngx-translate/core';
import { LinkedActivityParameters } from '@app/leasing-company/maintenance-intervals/shared/linked-activity-parameters';
import { LinkedActivitySummary } from '@app/leasing-company/maintenance-intervals/shared/linked-activity-summary';
import { RsFormatPaginatedListRequestBody } from '@lib/rs-ngx';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { LinkedActivityDetails } from '@shared/models/main-intervals/linked-activity-details';

@Injectable({
  providedIn: 'root'
})
export class LinkedActivitiesService {

  constructor(
        private readonly http: HttpClient,
        private readonly translateService: TranslateService
  ) {
  }

  public readonly getLinkedActivities = (
    filterParams: LinkedActivityParameters
  ): Observable<RsFormatPaginatedListRequestBody<LinkedActivitySummary[]>> => {
    const newParams = Object.assign({ userLanguage: this.translateService.currentLang }, filterParams);
    const params = Utils.getHttpParams(newParams);
    return this.http.get<LinkedActivitySummary[]>(
      environment.apiUrl + '/linked-activity',
      { observe: 'response', params }
    ).pipe(
      rsFormatPaginatedListRequestBody()
    );
  }

  public readonly getLinkedActivitiesTotalPages = (filterParams: LinkedActivityParameters): Observable<number> => {
    const newParams = Object.assign({ userLanguage: this.translateService.currentLang }, filterParams);
    const params = Utils.getHttpParams(newParams);
    return this.http.get<number>(
      environment.apiUrl + '/linked-activity/pagecount',
      { params }
    );
  }

  public readonly createLinkedActivity = (linkedActivity: LinkedActivitySummary): Observable<LinkedActivityDetails> => {
    return this.http.post<LinkedActivityDetails>(environment.apiUrl + `/linked-activity`, linkedActivity);
  }

  public readonly deleteLinkedActivityById = (id: number): Observable<void> => {
    return this.http.delete<void>(environment.apiUrl + `/linked-activity/${id}`);
  }
}
