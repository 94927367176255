import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { CURRENT_USER_DESTROYED, CURRENT_USER_INITIALIZED } from '@core/states/core.actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@core/states/core.reducer';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserGuard {

  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly store: Store<CoreState>
  ) {
  }

  public canActivate(
    url: string
  ): void {
    const isRsNumberPage = url.startsWith('/rs-number');

    if (
      !this.userService.getCurrentUser().getValue() &&
      !isRsNumberPage
    ) {
      this.router.navigate(['/rs-number']);
    }

    this.store.dispatch(isRsNumberPage ? CURRENT_USER_DESTROYED() : CURRENT_USER_INITIALIZED());
  }
}
