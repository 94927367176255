/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import {
  vehicleAdminOverviewFeatureKey,
  vehicleAdminOverviewInitialState,
  vehicleAdminReducer
} from '@app/admin/search-vehicle/state/search-vehicle-overview.reducer';
import {
  brandActivityMappingInitialState,
  brandActivityMappingReducer,
  overviewBrandActivityMappingFeatureKey
} from '@app/admin/brand-activity-mapping/overview/state/overview.reducer';
import {
  overviewReducer,
  vehicleTypesOverviewFeatureKey
} from '@app/admin/vehicle-types/overview/state/overview.reducer';
import {
  activitiesDefinitionOverviewFeatureKey, activitiesDefinitionOverviewReducer,
} from '@app/admin/activities/definition/overview/state/overview.reducer';
import {
  activitiesFuelOverviewFeatureKey,
  activitiesFuelOverviewReducer
} from '@app/admin/activities/fuel/overview/state/overview.reducer';
import {
  activitiesLocationOverviewFeatureKey,
  activitiesLocationOverviewReducer
} from '@app/admin/activities/location/overview/state/overview.reducer';
import {
  activitiesDefectTaskWarrantyOverviewFeatureKey,
  activitiesDefectTaskWarrantyOverviewReducer
} from '@app/admin/activities/defect-task-warranty/overview/state/overview.reducer';

export const adminRoutes: Routes =
  [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'activities',
    },
    {
      path: 'brand-activity-mappings',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/admin/brand-activity-mapping/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  overviewBrandActivityMappingFeatureKey,
                  brandActivityMappingReducer,
                  {
                    initialState: brandActivityMappingInitialState,
                  }
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/admin/brand-activity-mapping/add/add.component').then(component => component.AddComponent),
        },
        {
          path: ':id',
          loadComponent: () => import('@app/admin/brand-activity-mapping/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':id/edit',
          loadComponent: () => import('@app/admin/brand-activity-mapping/edit/edit.component').then(component => component.EditComponent),
        }
      ]
    },
    {
      path: 'activities',
      loadComponent: () => import('@app/admin/activities/activities.component').then(component => component.ActivitiesComponent),
      children: [
        {
          path: 'definition',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/admin/activities/definition/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule.forFeature(activitiesDefinitionOverviewFeatureKey, activitiesDefinitionOverviewReducer)
                )
              ],
            },
            {
              path: 'add',
              loadComponent: () => import('@app/admin/activities/definition/add/add.component').then(component => component.AddComponent),
            },
            {
              path: ':activityDefinitionId',
              loadComponent: () => import('@app/admin/activities/definition/details/details.component').then(component => component.DetailsComponent),
            },
            {
              path: ':activityDefinitionId/edit',
              loadComponent: () => import('@app/admin/activities/definition/edit/edit.component').then(component => component.EditComponent),
            }
          ]
        },
        {
          path: 'defect-task-warranty',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/admin/activities/defect-task-warranty/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule.forFeature(activitiesDefectTaskWarrantyOverviewFeatureKey, activitiesDefectTaskWarrantyOverviewReducer)
                )
              ],
            },
            {
              path: 'add',
              loadComponent: () => import('@app/admin/activities/defect-task-warranty/add/add.component').then(component => component.AddComponent),
            },
            {
              path: ':id',
              loadComponent: () => import('@app/admin/activities/defect-task-warranty/details/details.component').then(component => component.DetailsComponent),
            }
          ]
        },
        {
          path: 'fuel',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/admin/activities/fuel/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule.forFeature(activitiesFuelOverviewFeatureKey, activitiesFuelOverviewReducer)
                )
              ],
            },
            {
              path: 'add',
              loadComponent: () => import('@app/admin/activities/fuel/add/add.component').then(component => component.AddComponent),
            },
            {
              path: ':id',
              loadComponent: () => import('@app/admin/activities/fuel/details/details.component').then(component => component.DetailsComponent),
            },
          ]
        },
        {
          path: 'location',
          children: [
            {
              path: '',
              loadComponent: () => import('@app/admin/activities/location/overview/overview.component').then(component => component.OverviewComponent),
              providers: [
                importProvidersFrom(
                  StoreModule.forFeature(activitiesLocationOverviewFeatureKey, activitiesLocationOverviewReducer)
                )
              ]
            },
            {
              path: 'add',
              loadComponent: () => import('@app/admin/activities/location/add/add.component').then(component => component.AddComponent),
            },
            {
              path: ':id',
              loadComponent: () => import('@app/admin/activities/location/details/details.component').then(component => component.DetailsComponent),
            }
          ]
        },
        {
          path: '**',
          redirectTo: 'definition'
        }
      ],
    },
    {
      path: 'users',
      loadChildren: () =>
        import('@app/admin/users/users.module')
          .then(
            (module) => module.UsersModule
          ),
    },
    {
      path: 'suppliers',
      loadChildren: () =>
        import('@app/admin/suppliers/suppliers.module')
          .then(
            (module) => module.SuppliersModule
          ),
    },
    {
      path: 'vehicles',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/admin/search-vehicle/components/overview/overview.component').then(m => m.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule.forFeature(
                vehicleAdminOverviewFeatureKey,
                vehicleAdminReducer,
                { initialState: vehicleAdminOverviewInitialState }
              )
            )
          ],
        }
      ],
    },
    {
      path: 'work-orders',
      loadChildren: () =>
        import('@app/admin/work-orders/work-orders.module')
          .then(
            (module) => module.WorkOrdersModule
          ),
    },
    {
      path: 'activity-groups',
      loadChildren: () =>
        import('@app/admin/activity-groups/activity-groups.module')
          .then(
            (module) => module.ActivityGroupsModule
          ),
    },
    {
      path: 'vehicle-types',
      children: [
        {
          path: '',
          loadComponent: () => import('@app/admin/vehicle-types/overview/overview.component').then(component => component.OverviewComponent),
          providers: [
            importProvidersFrom(
              StoreModule
                .forFeature(
                  vehicleTypesOverviewFeatureKey,
                  overviewReducer
                )
            )
          ],
        },
        {
          path: 'add',
          loadComponent: () => import('@app/admin/vehicle-types/add/add.component').then(component => component.AddComponent),
        },
        {
          path: ':vehicleTypeId',
          loadComponent: () => import('@app/admin/vehicle-types/details/details.component').then(component => component.DetailsComponent),
        },
        {
          path: ':vehicleTypeId/edit',
          loadComponent: () => import('@app/admin/vehicle-types/edit/edit.component').then(component => component.EditComponent),
        }
      ]
    },
    {
      path: 'disapproval-reasons',
      loadChildren: () =>
        import('@app/admin/disapproval-reasons/disapproval-reasons.module')
          .then(
            (module) => module.DisapprovalReasonsModule
          ),
    },
    {
      path: 'rental-classes',
      loadChildren: () =>
        import('@app/admin/rental-classes/rental-classes.module')
          .then(
            (module) => module.RentalClassesModule
          ),
    },
    {
      path: 'warranties',
      loadChildren: () =>
        import('@app/admin/warranties/warranties.module')
          .then(
            (module) => module.WarrantiesModule
          ),
    },
    {
      path: 'fleet-owners',
      loadChildren: () =>
        import('@app/admin/fleet-owners/fleet-owners.module').then(
          (module) => module.FleetOwnersModule
        ),
    },
    {
      path: 'tyres',
      loadChildren: () =>
        import('@app/admin/tyres/tyres.module')
          .then(
            (module) => module.TyresModule
          ),
    },
  ];
