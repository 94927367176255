import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  SortableColumnsType,
  TyreMaintenanceParameters
} from '@app/leasing-company/tyre-maintenance/overview/state/overview.reducer';

export const updateTyreMaintenanceOverviewPaginator = createAction(
  '[LC Tyre Maintenance Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateTyreMaintenanceSorting = createAction(
  '[LC Tyre Maintenance Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeTyreMaintenanceFilters = createAction(
  '[LC Tyre Maintenance Overview] Store filters',
  props<{
        tableFilters: TyreMaintenanceParameters
    }>()
);
