import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';

const supplierRoles: string[] = ['SUPPLIER', 'DMS'];

export function isSupplierUserGuard(): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return (supplierRoles).includes(userService.getUserRole()) ? true : router.parseUrl('/rs-number');
  };
}
