import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DoubleMaintenanceSummaryActions from './double-maintenance-add.actions';
import { endWith, switchMap, tap } from 'rxjs/operators';
import { showHttpRequestSuccessMessage } from '@core/states/core.actions';
import { Router } from '@angular/router';
import { hideButtonSpinner } from '@core/states/show-spinner/show-spinner.actions';
import { DoubleMaintenanceService } from '@shared/services/leasing-company/main-intervals/double-maintenance.service';

@Injectable()
export class DoubleMaintenanceAddEffects {
  public createDoubleMaintenance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoubleMaintenanceSummaryActions.createDoubleMaintenance),
      switchMap(({ doubleMaintenance }) => {
        return this.doubleMaintenanceService
          .addDoubleMaintenance(doubleMaintenance)
          .pipe(
            switchMap((doubleMaintenanceId: number) => [
              showHttpRequestSuccessMessage(),
              DoubleMaintenanceSummaryActions.createDoubleMaintenanceSuccess({ doubleMaintenanceId })
            ]),
            endWith(hideButtonSpinner())
          );
      })
    );
  });

  public createDoubleMaintenanceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DoubleMaintenanceSummaryActions.createDoubleMaintenanceSuccess),
        tap(({ doubleMaintenanceId }) => {
          this.router.navigate(['leasing-company', 'maintenance-intervals', doubleMaintenanceId]).then();
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly doubleMaintenanceService: DoubleMaintenanceService,
    private readonly router: Router
  ) {
  }
}
