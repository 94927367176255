<mat-form-field
  (click)="monthDayPicker.open()"
  (onRsClearFormFieldButtonClicked)="clearField()"
  class="full-width rs-datepicker"
  rsAddClearFormFieldButton
>
  <mat-label>
    {{ label }}
  </mat-label>
  <input
    (dateChange)="change($event)"
    [disabled]="disabled"
    [matDatepicker]="monthDayPicker"
    [value]="value"
    matInput
    readonly
    type="text"
  />

  <mat-datepicker-toggle
    [for]="monthDayPicker"
    matSuffix
  >
  </mat-datepicker-toggle>

  <mat-datepicker
    #monthDayPicker
    startView="year"
  >
  </mat-datepicker>
</mat-form-field>