import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';


const DAY_MONTH_FORMAT = {
  parse: {
    dateInput: 'MM-DD',
  },
  display: {
    dateInput: 'DD/MM',
    monthYearLabel: 'MM',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MM',
  },
};

@Component({
  selector: 'ui-renta-month-day-picker',
  templateUrl: './month-day-picker.component.html',
  styleUrls: ['./month-day-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => MonthDayPickerComponent),
      multi: true,
    },

    { provide: MAT_DATE_FORMATS, useValue: DAY_MONTH_FORMAT },
  ],
})
export class MonthDayPickerComponent implements ControlValueAccessor {
  @Input() public label?: string;
  @Input() public disabled = false;
  public value?: Moment;
  public displayedValue = '';
  private day?: string;
  private month?: string;

  public writeValue(value: string): void {
    this.value = moment(value, 'MM-DD');
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public clearField(): void {
    this.value = undefined;
    this.onChange('');
    this.onTouched();
    this.writeValue('');
  }

  public change($event: MatDatepickerInputEvent<moment.Moment>): void {
    const momentValue = $event.value;
    if (momentValue) {
      this.month = momentValue.format('MM')
      this.day = momentValue.format('DD')
      const newDate = `${this.month}-${this.day}`;
      this.onChange(newDate);
      this.onTouched();
      this.writeValue(newDate);
    }

  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: Function = () => {
    // on changed
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: Function = () => {
    // on touched
  };
}
