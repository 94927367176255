/**
 * A utility function to be used in the rxjs filter pipe that filters out the null values and return a correctly typed observable.
 *
 * Usage:
 * ```typescript
 * pipe(
 *  filter(nonNull),
 * );
 * ```
 */
export const nonNull = <T>(value: T | null): value is T => value !== null;
