import { Action, createReducer, on } from '@ngrx/store';
import { ActivityLocationParameters } from '@shared/models/activities/location/activity-location-parameters';
import { updateActivitiesLocationOverviewFilter, updateActivitiesLocationOverviewPaginator, updateActivitiesLocationOverviewSort } from '@app/admin/activities/location/overview/state/overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';

export const activitiesLocationOverviewFeatureKey = 'activitiesLocationOverview';

export interface ActivitiesLocationOverviewState {
  params: ActivityLocationParameters;
  numberOfFilters: number
}

export const activitiesLocationOverviewState: ActivitiesLocationOverviewState = {
  params: {
    page: 1,
    resultsPerPage: 10,
    sortBy: 'ACTIVITY_CODE',
    sortDirection: 'ASC'
  },
  numberOfFilters: 0
};

export const _reducer = createReducer(
  activitiesLocationOverviewState,
  on(updateActivitiesLocationOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'activityCodeFrom',
      'activityCodeTo',
      'activityDescription',
    ]);

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params.page = 1;
      draft.numberOfFilters = numberOfFilters;
    });
  }),
  on(updateActivitiesLocationOverviewPaginator, (state, {
    resultsPerPage, page
  }) => {
    const newPage = state.params?.resultsPerPage != resultsPerPage ? 1 : page;
    return { ...state, params: { ...state.params, resultsPerPage, page: newPage } };
  }),
  on(updateActivitiesLocationOverviewSort, (state, {
    sortDirection, sortBy
  }) => {
    return { ...state, params: { ...state.params, sortBy, sortDirection } };
  })
);

export function activitiesLocationOverviewReducer(
    state: ActivitiesLocationOverviewState,
    action: Action
): ActivitiesLocationOverviewState {
  return _reducer(state, action);
}
