import { FeatureFlag } from '@core/feature-flag/feature-flag.model';
import { inject } from '@angular/core';
import { FeatureFlagService } from '@core/feature-flag/feature-flag.service';
import { map } from 'rxjs/operators';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';

export function isFeatureEnabled(featureFlag: FeatureFlag, redirectUrl?: string): CanActivateFn {
  return () => {
    const featureFlagService = inject(FeatureFlagService);
    const router = inject(Router);

    return featureFlagService.isFeatureEnabled(featureFlag).pipe(
      map(active => active ? true : router.parseUrl(redirectUrl || '/rs-number')),
    );
  };
}

export function matchIfFeatureEnabled(featureFlag: FeatureFlag): CanMatchFn {
  return () => inject(FeatureFlagService).isFeatureEnabled(featureFlag);
}
