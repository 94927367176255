import { Action, createReducer, on } from '@ngrx/store';
import { ActivityDefinitionParameters } from '@shared/models/activity-definition-parameters';
import { updateActivitiesDefinitionOverviewFilter, updateActivitiesDefinitionOverviewPaginator, updateActivitiesDefinitionOverviewSort } from '@app/admin/activities/definition/overview/state/overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';

export const activitiesDefinitionOverviewFeatureKey = 'activitiesDefinitionOverview';

export interface ActivitiesDefinitionOverviewState {
  params: ActivityDefinitionParameters;
  numberOfFilters: number;
}

export const activitiesDefinitionOverviewState: ActivitiesDefinitionOverviewState = {
  params: {
    _page: 1,
    _pageSize: 10,
    _sortBy: 'ACTIVITY_CODE',
    _sortDirection: 'ASC'
  },
  numberOfFilters: 0
};

export const _reducer = createReducer(
  activitiesDefinitionOverviewState,
  on(updateActivitiesDefinitionOverviewFilter, (state, { params }) => {
    const numberOfFilters = rsCountFilters(params, [
      'activityCodeFrom',
      'activityCodeTo',
      'activityDescription',
    ]);
    const activeFilterCount = params.active === '' ? 0 : 1;

    return produce(state, draft => {
      Object.assign(draft.params, params);
      draft.params._page = 1;
      draft.numberOfFilters = numberOfFilters + activeFilterCount;
    });
  }),
  on(updateActivitiesDefinitionOverviewPaginator, (state, {
    _pageSize, _page
  }) => {
    const newPage = state.params?._pageSize != _pageSize ? 1 : _page;
    return { ...state, params: { ...state.params, _pageSize, _page: newPage } };
  }),
  on(updateActivitiesDefinitionOverviewSort, (state, {
    _sortDirection, _sortBy
  }) => {
    return { ...state, params: { ...state.params, _sortBy, _sortDirection } };
  })
);

export function activitiesDefinitionOverviewReducer(
    state: ActivitiesDefinitionOverviewState,
    action: Action
): ActivitiesDefinitionOverviewState {
  return _reducer(state, action);
}
