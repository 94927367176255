import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { FebiacBrandService } from '../../services/febiac-brand.service';
import { loadFebiacModels, loadFebiacModelsSuccess } from './febiac-model.actions';

@Injectable()
export class FebiacModelEffects {
  public loadFebiacModels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFebiacModels),
      switchMap(({ code }) => {
        return this.febiacBrandService.getModelsForBrandCode(code).pipe(
          map((models) => {
            return loadFebiacModelsSuccess({ models });
          })
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly febiacBrandService: FebiacBrandService
  ) {
  }
}
