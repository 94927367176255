import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { storeTyreBrandDiscountFilters, updateTyreBrandDiscountOverviewPaginator, updateTyreBrandDiscountSorting } from '@app/leasing-company/tyre-brand-discount/general/overview/state/overview.actions';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewTyreBrandDiscountFeatureKey = 'overview-leasing-company-tyre-brand-discount';

export const sortableColumns = ['brand', 'supplierGroupDeviations'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface TyreBrandDiscountOverviewState {
  tableFilters: TyreBrandDiscountParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface TyreBrandDiscountParameters {
  brand?: string;
}

export const tyreBrandDiscountInitialState: TyreBrandDiscountOverviewState = {
  tableOptions: {
    sort: {
      active: 'brand',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {}
};

export const tyreBrandDiscountReducer = createReducer(
  tyreBrandDiscountInitialState,

  on(updateTyreBrandDiscountOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent
      }
    };
  }),

  on(updateTyreBrandDiscountSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort
      }
    }
  }),

  on(storeTyreBrandDiscountFilters, (state, { tableFilters }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters
      }
    };
  })
);

function localStorageSyncReducer(reducer: ActionReducer<TyreBrandDiscountOverviewState>): ActionReducer<TyreBrandDiscountOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_tyre_brand_discount_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const tyreBrandDiscountMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
