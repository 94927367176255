import { createAction, props } from '@ngrx/store';
import { LinkedActivityDetails } from '@shared/models/main-intervals/linked-activity-details';
import { LinkedActivitySummary } from '../../../shared/linked-activity-summary';

export const createLinkedActivities = createAction(
  '[LinkedActivities] Create LinkedActivities',
  props<{ linkedActivity: LinkedActivitySummary }>()
);

export const createLinkedActivitiesSuccess = createAction(
  '[LinkedActivities] Create LinkedActivities Success',
  props<{ linkedActivityDetails: LinkedActivityDetails }>()
);

