<main class="container">
  <section class="row rs-card">
    <ct-admin-info class="col-12">
      <ct-admin-info-basic-data-container>
        <h2>
          {{ adminData?.administration?.name | rsNullDash }}
          <span
            [ngClass]="adminData?.administration?.active ? 'rs-chip--green' : 'rs-chip--red'"
            class='ms-2'
          >
            {{ (adminData?.administration?.active ? 'ACTIVE' : 'INACTIVE') | translate}}
          </span>
        </h2>
        <ct-admin-value>{{ adminData?.administration?.number | rsNullDash }}</ct-admin-value>
      </ct-admin-info-basic-data-container>

      <ct-admin-parent-company-container>
        <ct-parent-company-value>
          {{ adminData?.administration?.parentLeasingCompany?.number + ' - ' + adminData?.administration?.parentLeasingCompany?.name | rsNullDash: '': '': { separatorsToStrip: ['-'] } }}
        </ct-parent-company-value>
        <ct-label>
          {{ 'PARENT_COMPANY' | translate }}
        </ct-label>
      </ct-admin-parent-company-container>

      <ct-admin-vehicule-number-container>
        <ct-parent-company-value>
          {{ adminData?.administration?.activeObjects }}
        </ct-parent-company-value>
        <ct-label>
          {{ 'VEHICLES' | translate }}
        </ct-label>
      </ct-admin-vehicule-number-container>
    </ct-admin-info>
  </section>

  <div class="row gap-lg-3 gap-1">
    <div class="rs-card col-12 col-lg">
      <ct-sub-title>
        <h2>
          {{ 'CONTACT' | translate }}
        </h2>
      </ct-sub-title>

      <ct-row-divider class="d-flex rs-divider mb-3"></ct-row-divider>

      <ct-icon-item>
        <mat-icon>email</mat-icon>
        {{ adminData?.administration?.email | rsNullDash }}
      </ct-icon-item>

      <ct-icon-item>
        <mat-icon>smartphone</mat-icon>
        {{ adminData?.administration?.phoneNumber | rsNullDash }}
      </ct-icon-item>

      <ct-icon-item>
        <mat-icon>print</mat-icon>
        {{ adminData?.administration?.fax | rsNullDash }}
      </ct-icon-item>

      <ct-icon-item>
        <mat-icon>chat_bubble_outline</mat-icon>
        {{ adminData?.administration?.officialLanguage | rsNullDash }}
      </ct-icon-item>
    </div>

    <div class="rs-card col-12 col-lg-8">
      <ct-sub-title>
        <h2>
          {{ 'BILLING' | translate }}
        </h2>
      </ct-sub-title>

      <ct-row-divider class="d-flex rs-divider mb-3"></ct-row-divider>

      <ct-billing-name>
        <ct-label> {{'BILLING_NAME' | translate}}</ct-label>
        <ct-value>{{ adminData?.administration?.invoiceName | rsNullDash}}</ct-value>
      </ct-billing-name>


      <ct-address>
        <ct-label>{{'ADDRESS' | translate}}</ct-label>
        <ct-value>{{ adminData?.administration?.address | postalAddress}}</ct-value>
      </ct-address>

      <ct-country>
        <ct-label>{{'COUNTRY' | translate}}</ct-label>
        <ct-value>{{ adminData?.administration?.address?.country | rsNullDash}}</ct-value>
      </ct-country>


      <ct-vat-number>
        <ct-label>{{'VAT_NUMBER' | translate}}</ct-label>
        <ct-value>{{ adminData?.administration?.vatNumber | rsNullDash}}</ct-value>
      </ct-vat-number>

      <ct-vat-unit>
        <ct-label>{{'VAT_UNIT' | translate}}</ct-label>
        <ct-value>{{ adminData?.administration?.vatUnit | rsNullDash}}</ct-value>
      </ct-vat-unit>


    </div>
  </div>

</main>