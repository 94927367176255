import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  LeasingContractGroupParameters,
  SortableColumnsType
} from '@app/leasing-company/leasing-contract-group/overview/state/overview.reducer';


export const updateLeasingContractGroupOverviewPaginator = createAction(
  '[LC Leasing Contract Group Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateLeasingContractGroupSorting = createAction(
  '[LC Leasing Contract Group Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeLeasingContractGroupFilters = createAction(
  '[LC Leasing Contract Group Overview] Store filters',
  props<{
      tableFilters: LeasingContractGroupParameters
  }>()
);
