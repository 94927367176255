import { createAction, props } from '@ngrx/store';
import { ActivityLocationParameters } from '@shared/models/activities/location/activity-location-parameters';

export const updateActivitiesLocationOverviewFilter = createAction(
  '[Overview] Update ActivitiesLocationOverview Filter',
  props<{ params: ActivityLocationParameters }>()
);

export const updateActivitiesLocationOverviewPaginator = createAction(
  '[Overview] Update ActivitiesLocationOverview Paginator',
  props<{
    resultsPerPage: number;
    page: number;
  }>()
);

export const updateActivitiesLocationOverviewSort = createAction(
  '[Overview] Update ActivitiesLocationOverview Sort',
  props<{
    sortDirection: string;
    sortBy: string;
  }>()
);




