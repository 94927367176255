import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import {
  SortableColumnsType,
  TyreBrandDiscountParameters
} from '@app/leasing-company/tyre-brand-discount/general/overview/state/overview.reducer';

export const updateTyreBrandDiscountOverviewPaginator = createAction(
  '[LC Tyre Brand Discount Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateTyreBrandDiscountSorting = createAction(
  '[LC Tyre Brand Discount Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeTyreBrandDiscountFilters = createAction(
  '[LC Tyre Brand Discount Overview] Store filters',
  props<{
      tableFilters: TyreBrandDiscountParameters
  }>()
);
