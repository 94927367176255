import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FebiacBrand } from '@shared/models/febiac/febiac-brand';
import { Observable, shareReplay } from 'rxjs';
import { FebiacModelCode } from '@shared/models/febiac/febiac-model-code';
import { rsSortByProperty } from '@lib/utils/custom-rxjs-operators/rsSortAlphabetically';

@Injectable({
  providedIn: 'root'
})
export class FebiacBrandService {
  private readonly febiacBrands$ = this.http.get<FebiacBrand[]>(`${environment.apiUrl}/febiac-brand`)
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

  constructor(private readonly http: HttpClient) {
  }

  public readonly getFebiacBrands = (): Observable<FebiacBrand[]> => {
    return this.febiacBrands$;
  }

  public readonly getSortedFebiacBrandsByParam = (propertyToSortBy: keyof FebiacBrand): Observable<FebiacBrand[]> => {
    return this.getFebiacBrands().pipe(
      rsSortByProperty(propertyToSortBy)
    );
  }

  public readonly getModelsForBrandCode = (code: string): Observable<FebiacModelCode[]> => {
    return this.http.get<FebiacModelCode[]>(
      `${environment.apiUrl}/febiac-brand/${code}/model`
    );
  }
}
