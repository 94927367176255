import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import { PreferredTyreBrandParameters } from '@shared/models/preferred-tyre-brand/preferred-tyre-brand-parameters';
import { SortableColumnsType } from '@app/leasing-company/preferred-tyre-brand/overview/state/overview.reducer';

export const updatePreferredTyreBrandOverviewPaginator = createAction(
  '[LC Preferred Tyre Type Overview] Update preferred tyre type paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updatePreferredTyreBrandSorting = createAction(
  '[LC Preferred Tyre Type Overview] Update preferred tyre type sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>(),
);

export const storePreferredTyreBrandFilters = createAction(
  '[LC Preferred Tyre Type Overview] Store preferred tyre type filters',
  props<{
      tableFilters: PreferredTyreBrandParameters
  }>()
);
