import { Observable } from 'rxjs';
import { WorkOrderDetails } from '@shared/models/supplier/work-order/work-order-details';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { WorkOrderService } from '@shared/services/supplier/work-order/work-order.service';
import { RsLoaderService } from '@lib/rs-ngx';
import { finalize } from 'rxjs/operators';

export const workOrderDetailsResolver: ResolveFn<WorkOrderDetails> = (route: ActivatedRouteSnapshot): Observable<WorkOrderDetails> => {
  const workOrderService = inject(WorkOrderService);
  const spinner = inject(RsLoaderService);
  spinner.show({ id: 'load-work-order-details' });
  return workOrderService.getWorkOrderDetails(route.paramMap.get('id')!)
    .pipe(
      finalize(() => spinner.hide({ id: 'load-work-order-details' }))
    );
}
