import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DoubleMaintenanceParameters } from '@shared/models/main-intervals/double-maintenance/double-maintenance-parameters';
import Utils from '../../../../utils';
import { environment } from '@environments/environment';
import { DoubleMaintenanceSummary } from '@app/leasing-company/maintenance-intervals/double-maintenance/shared/models/double-maintenance-summary';
import {
  DoubleMaintenanceDetails
} from '@app/leasing-company/maintenance-intervals/double-maintenance/shared/models/double-maintenance-details';
import { LinkedActivityParameters } from '@app/leasing-company/maintenance-intervals/shared/linked-activity-parameters';
import { LinkedActivitySummary } from '@app/leasing-company/maintenance-intervals/shared/linked-activity-summary';
import { TranslateService } from '@ngx-translate/core';
import { DoubleMaintenanceCreate, DoubleMaintenanceUpdate } from '@shared/models/main-intervals/double-maintenance/double-maintenance';

@Injectable({
  providedIn: 'root'
})
export class DoubleMaintenanceService {

  private readonly http =  inject(HttpClient);
  private readonly translateService =  inject(TranslateService);

  public readonly getDoubleMaintenances = (filter: DoubleMaintenanceParameters): Observable<DoubleMaintenanceSummary[]> => {
    const params = Utils.getHttpParams(filter);
    return this.http.get<DoubleMaintenanceSummary[]>(`${environment.apiUrl}/leasing-company/double-maintenance`, {
      params
    });
  }

  public readonly getDoubleMaintenancesTotalPages = (filter: DoubleMaintenanceParameters): Observable<number> => {
    const params = Utils.getHttpParams(filter);
    return this.http.get<number>(
      environment.apiUrl + '/leasing-company/double-maintenance/pagecount',
      { params }
    )
  }

  public readonly getLinkedActivities = (filterParams: LinkedActivityParameters): Observable<LinkedActivitySummary[]> => {
    const newParams = Object.assign({ userLanguage: this.translateService.currentLang }, filterParams);
    const params = Utils.getHttpParams(newParams);
    return this.http.get<LinkedActivitySummary[]>(
      environment.apiUrl + '/linked-activity',
      { params }
    );
  }

  public readonly getDoubleMaintenanceById = (id: number): Observable<DoubleMaintenanceDetails> => {
    return this.http.get<DoubleMaintenanceDetails>(
      environment.apiUrl + `/leasing-company/double-maintenance/${id}`
    );
  }

  public readonly addDoubleMaintenance = (doubleMaintenanceToCreate: DoubleMaintenanceCreate): Observable<number> => {
    return this.http.post<number>(`${environment.apiUrl}/leasing-company/double-maintenance`, doubleMaintenanceToCreate);
  }

  public readonly updateDoubleMaintenanceDetailsById = (id: number, request: DoubleMaintenanceUpdate): Observable<void> => {
    return this.http.put<void>(
      environment.apiUrl + `/leasing-company/double-maintenance/${id}`,
      request
    );
  }

  public readonly deleteDoubleMaintenance = (id: number): Observable<void> => {
    return this.http.delete<void>(
      environment.apiUrl + `/leasing-company/double-maintenance/${id}`
    );
  }
}
