import { createAction, props } from '@ngrx/store';
import { VehicleAdminOverviewState } from '@app/admin/search-vehicle/state/search-vehicle-overview.reducer';

export const updateTablePagination = createAction(
  '[Overview] Update Vehicle Admin Overview Table',
  props<{ paging: VehicleAdminOverviewState['paging'] }>()
);

export const updateDrawerFilters = createAction(
  '[Overview] Update Vehicle Admin Overview Drawer Filters',
  props<{ filters: VehicleAdminOverviewState['filters'] }>()
);
