import { createReducer, on } from '@ngrx/store';
import { updateDrawerFilters, updateTablePagination } from '@app/admin/search-vehicle/state/search-vehicle-overview.actions';

export const vehicleAdminOverviewFeatureKey = 'search-vehicle-overview';

export const vehicleAdminOverviewInitialState: VehicleAdminOverviewState = {
  filters: {
    active: null,
    licensePlate: '',
    vin: ''
  },
  paging: {
    pageIndex: 0,
    pageSize: 10
  }
}

export const vehicleAdminReducer = createReducer(
  vehicleAdminOverviewInitialState,
  on(updateTablePagination, (state, {
    paging
  }) => {
    const newPage = state.paging.pageSize != paging.pageSize ? 0 : paging.pageIndex;
    return {
      ...state,
      paging: {
        ...state.paging,
        ...paging,
        pageIndex: newPage,
      },
    };
  }),
  on(updateDrawerFilters, (state, { filters }) => {
    return {
      ...state,
      filters,
      paging: {
        ...state.paging,
        pageIndex: 0,
      },
    };
  })
);

export interface VehicleAdminOverviewState {
    filters: {
        active: boolean | null,
        licensePlate: string,
        vin: string
    }
    paging: {
        pageIndex: number,
        pageSize: number,
    },
}
