import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { WorkOrderSummary } from '@shared/models/supplier/work-order/work-order-summary';
import { RsFormatPaginatedListRequestBody, UUID } from '@lib/rs-ngx';
import Utils from '@app/utils';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { WorkOrderQueryParameters } from '@shared/models/supplier/work-order/work-order-parameters';
import { WorkOrderActivities } from '@shared/models/supplier/work-order/work-order-activity-details';
import { WorkOrderDetails } from '@shared/models/supplier/work-order/work-order-details';
import { WorkOrderDocument } from '@shared/models/work-order-document';
import { WorkOrderCreateRequest } from '@shared/models/supplier/work-order/work-order-create-request';
import { WorkOrderUpdateRequest } from '@shared/models/supplier/work-order/work-order-update-request';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {

  constructor(private readonly http: HttpClient) {
  }

  public readonly getWorkOrders = (filterParams: WorkOrderQueryParameters): Observable<RsFormatPaginatedListRequestBody<WorkOrderSummary[]>> => {
    const params = Utils.getHttpParams(filterParams);
    return this.http.get<WorkOrderSummary[]>(
      `${environment.apiUrl}/supplier/work-order`,
      { observe: 'response', params }
    ).pipe(
      rsFormatPaginatedListRequestBody()
    );
  }

  public readonly getWorkOrderActivities = (workOrderId: string): Observable<WorkOrderActivities> => {
    return this.http.get<WorkOrderActivities>(`${environment.apiUrl}/supplier/work-order/${workOrderId}/activity`);
  };

  public readonly getWorkOrderDetails = (workOrderId: string): Observable<WorkOrderDetails> => {
    return this.http.get<WorkOrderDetails>(`${environment.apiUrl}/supplier/work-order/${workOrderId}`);
  };

  public readonly getWorkOrderDocumentsById = (id: string): Observable<WorkOrderDocument[]> => {
    return this.http.get<WorkOrderDocument[]>(
      environment.apiUrl + `/supplier/work-order/${id}/attachment`
    );
  };

  public readonly getWorkOrderAttachmentByAttachmentId = (attachmentId: number): Observable<HttpResponse<Blob>> => {
    return this.http.get<HttpResponse<Blob>>(environment.apiUrl + `/supplier/work-order-attachment/${attachmentId}`, {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    })
  }

  public readonly saveWorkOrder = (workOrderCreateRequest: WorkOrderCreateRequest): Observable<UUID> => {
    return this.http.post<UUID>(`${environment.apiUrl}/supplier/work-order`, workOrderCreateRequest);
  }

  public readonly updateWorkOrder = (id: UUID, workOrderUpdateRequest: WorkOrderUpdateRequest): Observable<void> => {
    return this.http.put<void>(`${environment.apiUrl}/supplier/work-order/${id}`, workOrderUpdateRequest);
  }
}
