import { createAction, props } from '@ngrx/store';

import { SortDirection } from '@shared/models/sort-direction';
import {
  TyreBrandDiscountSupplierGroupParameters
} from '@shared/models/tyre-brand-discount/supplier-group/tyre-brand-discount-parameters';

export const updateTyreBrandDiscountSupplierGroupOverviewPaginator = createAction(
  '[LC Tyre Brand Discount Supplier Group Overview] Update paginator',
  props<{
        resultsPerPage: number;
        page: number;
    }>()
);

export const updateTyreBrandDiscountSupplierGroupSorting = createAction(
  '[LC Tyre Brand Discount Supplier Group Overview] Update sorting',
  props<{
        sortDirection: SortDirection;
        sortBy: string;
    }>()
);

export const storeTyreBrandDiscountSupplierGroupFilters = createAction(
  '[LC Tyre Brand Discount Supplier Group Overview] Store filters',
  props<{
        tableFilters: TyreBrandDiscountSupplierGroupParameters
    }>()
);
