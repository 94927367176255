import { createReducer, on } from '@ngrx/store';
import { storeBrandActivityMappingFilters, updateBrandActivityMappingOverviewPaginator } from '@app/admin/brand-activity-mapping/overview/state/overview.actions';
import { BrandActivityMappingParameters, OverviewParams } from '@shared/models/brand-activity-mapping/brand-activity-mapping-parameters';

export const overviewBrandActivityMappingFeatureKey = 'overview-brand-activity-mappings';

export interface BrandActivityMappingOverviewState {
  paginationParams: OverviewParams;
  filterParams: BrandActivityMappingParameters;
}

export const brandActivityMappingInitialState: BrandActivityMappingOverviewState = {
  paginationParams: {
    _page: 1,
    _pageSize: 10
  },
  filterParams: {}
};

export const brandActivityMappingReducer = createReducer(
  brandActivityMappingInitialState,

  on(updateBrandActivityMappingOverviewPaginator, (state, {
    resultsPerPage,
    page
  }) => {
    return {
      ...state,
      paginationParams: {
        ...state.paginationParams,
        _pageSize: resultsPerPage,
        _page: state.paginationParams._pageSize !== resultsPerPage ? 1 : page
      }
    };
  }),

  on(storeBrandActivityMappingFilters, (state, {
    filterParams
  }) => {
    return {
      ...state,
      paginationParams: {
        ...state.paginationParams,
        _page: 1
      },
      filterParams: {
        ...state.filterParams,
        ...filterParams
      }
    };
  })
);


