import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';

const leasingCompanyRoles = ['LEASING_COMPANY', 'LEASING_COMPANY_WITH_AUTHORIZATION', 'LEASING_COMPANY_MANAGER'] as const;
type LeasingCompanyRole = typeof leasingCompanyRoles[number];

const defaultOptions = {
  roles: ['LEASING_COMPANY', 'LEASING_COMPANY_WITH_AUTHORIZATION', 'LEASING_COMPANY_MANAGER'] as LeasingCompanyRole[],
  urlToRedirect: '/rs-number',
};

export function isLeasingCompanyUserGuard({
  roles = defaultOptions.roles,
  urlToRedirect = defaultOptions.urlToRedirect,
}: Partial<typeof defaultOptions> = defaultOptions): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return (roles as string[]).includes(userService.getUserRole()) ? true : router.parseUrl(urlToRedirect);
  };
}
