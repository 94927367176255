import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import { SortableColumnsType } from '@app/supplier/work-order/overview/state/overview.reducer';
import { WorkOrderParameters } from '@shared/models/supplier/work-order/work-order-parameters';


export const updateWorkOrderOverviewPaginator = createAction(
  '[SUPPLIER Work Order Overview] Update paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateWorkOrderSorting = createAction(
  '[SUPPLIER Work Order Overview] Update sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>()
);

export const storeWorkOrderFilters = createAction(
  '[SUPPLIER Work Order Overview] Store filters',
  props<{
      tableFilters: WorkOrderParameters
  }>()
);

export const updateWorkOrderSearchTerm = createAction(
  '[SUPPLIER Work Order Overview] Update search term',
  props<{
      searchTerm: string
  }>()
);
