import { FormControl } from '@angular/forms';

export enum TyreType {
    ALL_WEATHER = 'A',
    SUMMER = 'Z',
    WINTER = 'W'
}

export interface TyreTypeFormGroup {
    ALL_WEATHER: FormControl<boolean>,
    SUMMER: FormControl<boolean>,
    WINTER: FormControl<boolean>,
}
