<main class="container mt-40px">
  <rs-card>
    <p>
      {{ 'RS_NUMBER_PAGE.MULTIPLE_RSNUMBERS' | translate }}<br />
      {{ 'RS_NUMBER_PAGE.SUBTITLE' | translate }}
    </p>

    <button
      [matMenuTriggerFor]="rsUserMenu"
      class="rs-btn--primary mt-15px"
      data-testid="button-open-menu-rs-user"
      mat-button
    >
      {{ 'RS_NUMBER_PAGE.SELECT_FIELD_PLACEHOLDER' | translate }}
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>

    <mat-menu
      #rsUserMenu="matMenu"
      class="mw-100"
    >
      <button
        (click)="selectRsUser(user)"
        *ngFor="let user of users()"
        [attr.data-testid]="'rs-user-' + user.loginString"
        mat-menu-item
      >
        {{ user?.companyName }} - {{ user?.loginString }}
      </button>
    </mat-menu>
  </rs-card>
</main>