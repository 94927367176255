import { CoreModule } from './app/core/core.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://ca2f68b0be0f49b89120618a5041e48a@o268277.ingest.sentry.io/5469054',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.configureScope((scope): void => {
  scope.setTag('app.name', 'MRT');
  scope.setTag('app.environment', environment.environment as string);
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(CoreModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
