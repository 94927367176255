import { Action, createReducer, on } from '@ngrx/store';
import { LinkedActivityParameters } from '../../../../shared/linked-activity-parameters';
import { FilterParameters } from '@shared/models/filter-parameters';
import {
  updateLinkedActivitiesOverviewFilters,
  updateLinkedActivitiesOverviewFiltersPage, updateLinkedActivitiesOverviewFiltersSort
} from '@app/leasing-company/maintenance-intervals/linked-activities/overview/components/state/linked-activities-overview-filters.actions';

export const linkedActivitiesOverviewFilterFeatureKey = 'linkedActivitiesOverviewFilter';

export interface LinkedActivitiesOverviewFiltersState {
    filterParams: LinkedActivityParameters,
    paginationParams: FilterParameters;
    numberOfFilters: number;
}

export const initialState: LinkedActivitiesOverviewFiltersState = {
  paginationParams: {
    _page: 1,
    _pageSize: 10,
    _sortBy: 'ACTIVITY_DESCRIPTION',
    _sortDirection: 'ASC'
  },
  filterParams: {},
  numberOfFilters: 0
};


export const reducer = createReducer(
  initialState,

  on(updateLinkedActivitiesOverviewFiltersPage, (state, {
    resultsPerPage,
    page
  }) => {
    return {
      ...state,
      paginationParams: {
        ...state.paginationParams,
        _pageSize: resultsPerPage,
        _page: page
      }
    }
  }),
  on(updateLinkedActivitiesOverviewFilters, (state, { filterParams }) => {

    return {
      ...state,
      paginationParams: {
        ...state.paginationParams,
        _page: 1
      },
      filterParams: {
        ...state.filterParams,
        ...filterParams
      }
    }
  }),

  on(updateLinkedActivitiesOverviewFiltersSort, (state, {
    sortDirection, sortBy
  }) => {
    return {
      ...state,
      paginationParams: {
        ...state.paginationParams,
        _sortDirection: sortDirection,
        _sortBy: sortBy
      }
    }
  })
);

export function linkedActivitiesOverviewFiltersReducer(
    state: LinkedActivitiesOverviewFiltersState,
    action: Action,
): LinkedActivitiesOverviewFiltersState {
  return reducer(state, action);
}


