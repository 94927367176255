import { HttpParams } from '@angular/common/http';
import { LegacyFilterParameters } from '@shared/models/filter-parameters';
import { UntypedFormGroup } from '@angular/forms';
import { TyreType } from '@shared/models/tyre/tyre-type';
import { VehicleType } from '@shared/models/vehicle/vehicle-type';
import { FebiacBrand } from '@shared/models/febiac/febiac-brand';
import { MappedModel } from '@app/leasing-company/data-mapping/models/models/mapped-model';
import { isArray } from 'lodash';

export default class Utils {
  public static isElementInViewport(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public static getHttpParams(filterParams: LegacyFilterParameters): HttpParams {
    let params = new HttpParams();
    Object.keys(filterParams).forEach(function (key) {
      if (isArray(filterParams[key])) {
        filterParams[key].forEach((value: string | number) => {
          params = params.append(`${key}`, value)
        })
      } else if (
        filterParams[key] !== undefined &&
                filterParams[key] !== '' &&
                filterParams[key] !== null
      ) {
        params = params.append(key, filterParams[key] as string | number | boolean);
      }
    });
    return params;
  }

  public static getStockingSupplierRequired(tyreInfoForm: UntypedFormGroup): boolean {
    const currentTyreType = tyreInfoForm.get('tyreCounters.tyreType')?.value;

    const winterReplaced =
            (currentTyreType === TyreType.SUMMER || currentTyreType === TyreType.ALL_WEATHER) &&
            tyreInfoForm.get('tyreCounters.numberOfReplacementWinterTyres')?.value > 0;

    const otherReplaced =
            currentTyreType === TyreType.WINTER &&
            (tyreInfoForm.get('tyreCounters.numberOfReplacementSummerTyres')?.value > 0
                || tyreInfoForm.get('tyreCounters.numberOfReplacementAllWeatherTyres')?.value > 0);

    return winterReplaced || otherReplaced;
  }

  public static getBooleanFromString(str: string | undefined): boolean | undefined {
    switch (str?.toLowerCase()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }

  public static compareVehicleTypes(v1: VehicleType, v2: VehicleType): boolean {
    return !!v1?.id && !!v2?.id && v1?.id === v2?.id;
  }

  public static compareFebiacBrandByDescription(brand: FebiacBrand, brand2: FebiacBrand): boolean {
    return brand?.description === brand2?.description;
  }

  public static compareFebiacModel(model: MappedModel, model2: MappedModel): boolean {
    return model?.id === model2?.id;
  }

  public static compareById(v1: { id: unknown }, v2: { id: unknown }): boolean {
    return v1.id === v2.id;
  }

  public static compareByCode(a1: { code: unknown }, b1: { code: unknown }): boolean {
    return a1.code === b1.code;
  }

  public static download(blob: Blob, fileName: string): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
}
