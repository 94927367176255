import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { storeBrandThresholdFilters, updateBrandThresholdOverviewPaginator, updateBrandThresholdSorting } from '@app/leasing-company/brand-threshold/overview/state/overview.actions';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewBrandThresholdFeatureKey = 'overview-leasing-company-brand-threshold';

export const sortableColumns = ['brand'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface BrandThresholdOverviewState {
  tableFilters: BrandThresholdParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface BrandThresholdParameters {
  brand?: string;
}

export const brandThresholdInitialState: BrandThresholdOverviewState = {
  tableOptions: {
    sort: {
      active: 'brand',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {}
};

export const brandThresholdReducer = createReducer(
  brandThresholdInitialState,

  on(updateBrandThresholdOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent
      }
    };
  }),

  on(updateBrandThresholdSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort
      }
    }
  }),

  on(storeBrandThresholdFilters, (state, { tableFilters }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters
      }
    };
  })
);

function localStorageSyncReducer(reducer: ActionReducer<BrandThresholdOverviewState>): ActionReducer<BrandThresholdOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_brand_threshold_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const brandThresholdMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
