/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RsNumberInterceptor } from '@core/http-interceptors/rs-number/rs-number.interceptor';
import { RsDateFormatterInterceptor } from '@rs-shared-lib/interceptors/date-formatter/date-formatter.interceptor';

/** Http interceptor providers in outside-in order */
export const coreInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RsNumberInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RsDateFormatterInterceptor,
    multi: true
  },
];
