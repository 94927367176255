import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { Nullable, TableOptions } from '@lib/rs-ngx';
import { storeTyreMaintenanceFilters, updateTyreMaintenanceOverviewPaginator, updateTyreMaintenanceSorting } from '@app/leasing-company/tyre-maintenance/overview/state/overview.actions';
import { VehicleType } from '@shared/models/vehicle/vehicle-type';
import { produce } from 'immer';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewTyreMaintenanceFeatureKey = 'overview-leasing-company-tyre-maintenance';

export const sortableColumns = ['vehicleType'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface TyreMaintenanceOverviewState {
    tableFilters: TyreMaintenanceParameters;
    tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface TyreMaintenanceParameters {
  vehicleType: Nullable<VehicleType>;
    tyreTypes: {
        ALL_WEATHER: boolean,
        SUMMER: boolean,
        WINTER: boolean,
        ALL_TYRE_TYPES: boolean
    };
}

export const tyreMaintenanceInitialState: TyreMaintenanceOverviewState = {
  tableOptions: {
    sort: {
      active: 'vehicleType',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {
    vehicleType: null,
    tyreTypes: {
      ALL_WEATHER: false,
      SUMMER: false,
      WINTER: false,
      ALL_TYRE_TYPES: false,
    }
  }
};

export const tyreMaintenanceReducer = createReducer(
  tyreMaintenanceInitialState,

  on(updateTyreMaintenanceOverviewPaginator, (state, { pageEvent }) =>
    produce(state, draft => {
      draft.tableOptions.pageEvent = pageEvent;
    })),

  on(updateTyreMaintenanceSorting, (state, { sort }) =>
    produce(state, draft => {
      draft.tableOptions.sort = sort;
    })),

  on(storeTyreMaintenanceFilters, (state, { tableFilters }) =>
    produce(state, draft => {
      draft.tableFilters = { ...draft.tableFilters, ...tableFilters };
    }))
);

function localStorageSyncReducer(reducer: ActionReducer<TyreMaintenanceOverviewState>): ActionReducer<TyreMaintenanceOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_tyre_maintenance_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const tyreMaintenanceMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
