import { createAction, props } from '@ngrx/store';
import { ActivityDefectTaskWarrantyFromToParameters } from '@shared/models/activity-defect-task-warranty-parameters';

export const updateActivitiesDefectTaskWarrantyOverviewFilter = createAction(
  '[Overview] Update ActivitiesDefectTaskWarrantyOverview Filter',
  props<{ params: ActivityDefectTaskWarrantyFromToParameters }>()
);

export const updateActivitiesDefectTaskWarrantyOverviewPaginator = createAction(
  '[Overview] Update ActivitiesDefectTaskWarrantyOverview Paginator',
  props<{
    _pageSize: number;
    _page: number;
  }>()
);

export const updateActivitiesDefectTaskWarrantyOverviewSort = createAction(
  '[Overview] Update ActivitiesDefectTaskWarrantyOverview Sort',
  props<{
    _sortDirection: string;
    _sortBy: string;
  }>()
);




