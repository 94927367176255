import { ActivatedRouteSnapshot, MaybeAsync, RedirectCommand, ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { map } from 'rxjs/operators';
import { User } from '@core/models/user';

export function rsNumberSelectionResolver(options: { autoRedirect: boolean }): ResolveFn<User[]> {
  return (route: ActivatedRouteSnapshot): MaybeAsync<User[] | RedirectCommand> => {
    const userService = inject(UserService);
    const router = inject(Router);
    const rsUserNumberParameter = route.queryParams['rsUserNumber'] as string;
    const users$ = userService.getAvailableUsers();

    if (options.autoRedirect && userService.getCurrentUser().getValue()) {
      return redirectUserToLandingPage(userService, router);
    }

    return users$.pipe(
      map(users => {
        const userToLogin = findSingleUser(users, rsUserNumberParameter);
        if (userToLogin) {
          userService.setCurrentUser(userToLogin);
          return (options.autoRedirect || rsUserNumberParameter) ? redirectUserToLandingPage(userService, router): users;
        } else {
          return users;
        }
      })
    );
  };
}

function findSingleUser(users: User[], rsUserNumber?: string): User | undefined {
  if (rsUserNumber) {
    return users.find(user => user.loginString === rsUserNumber);
  } else {
    return users.length === 1 ? users[0]: undefined;
  }
}

function redirectUserToLandingPage(userService: UserService, router: Router): RedirectCommand {
  return new RedirectCommand(router.parseUrl(userService.getDefaultRedirectURL()));
}
