import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { createLinkedActivities, createLinkedActivitiesSuccess } from './linked-activities-add.actions';
import { endWith, switchMap, tap } from 'rxjs/operators';
import { showHttpRequestSuccessMessage } from '@core/states/core.actions';
import { hideButtonSpinner } from '@core/states/show-spinner/show-spinner.actions';
import { LinkedActivitiesService } from '@shared/services/leasing-company/main-intervals/linked-activities.service';

@Injectable()
export class LinkedActivitiesAddEffects {

  public createLinkedActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createLinkedActivities),
      switchMap(({ linkedActivity }) =>
        this.linkedActivitiesService
          .createLinkedActivity(linkedActivity)
          .pipe(
            switchMap((linkedActivityDetails) => [
              createLinkedActivitiesSuccess({ linkedActivityDetails }),
              showHttpRequestSuccessMessage()
            ]),
            endWith(hideButtonSpinner())
          ))
    )
  });
  public redirectLinkedActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createLinkedActivitiesSuccess),
      tap(() => this.router.navigate(['leasing-company', 'maintenance-intervals', 'linked-activities']))
    );
  }, { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly linkedActivitiesService: LinkedActivitiesService
  ) {
  }


}
