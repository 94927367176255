import { Action, createReducer, on } from '@ngrx/store';
import { FebiacModelCode } from '../../../shared/models/febiac/febiac-model-code';
import { loadFebiacModelsSuccess } from './febiac-model.actions';

export const febiacModelFeatureKey = 'febiacModel';

export type FebiacModelState = {
  models: FebiacModelCode[];
};

export const initialState: Partial<FebiacModelState> = {
  models: [],
};

export const _reducer = createReducer(
  initialState,

  on(loadFebiacModelsSuccess, (state, { models }) => {
    return { ...state, models };
  })
);

export function febiacModelReducer(
    state: FebiacModelState,
    action: Action
): Partial<FebiacModelState> {
  return _reducer(state, action);
}
