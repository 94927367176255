<rs-top-bar-menu
  (onLogout)="resetStateOnLogout()"
  (onRouteChange)="topBarRouteChange($event)"
  [application]="'MRT'"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment === 'dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
  [menuItems]="menuItems()"
  sortSubMenuItemsBy='ASC'
/>

<rs-loader />

<router-outlet [attr.data-app-version]='appVersion' />

<rs-footer
  [cookiesKey]="'FOOTER.COOKIES'"
  [disclaimerKey]="'FOOTER.DISCLAIMER'"
  [needHelpKey]="'FOOTER.NEED_HELP'"
/>