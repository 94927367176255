import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { environment } from '@environments/environment';

@Injectable()
export class RsNumberInterceptor implements HttpInterceptor {
  private readonly INTERNAL_API_URLS = [
    environment.apiUrl,
    environment.apiBatchUrl
  ];

  constructor(private readonly userService: UserService) {
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const currentUser = this.userService.getCurrentUser().getValue();

    if (currentUser && this.isRequestToInternalApi(request.url)) {
      request = request.clone({
        setHeaders: {
          'X-WSS-rs-user-number': currentUser.loginString,
        },
      });
    }

    return next.handle(request);
  }

  private isRequestToInternalApi(requestUrl: string): boolean {
    return this.INTERNAL_API_URLS.some(url => requestUrl.includes(url));
  }
}
