import { createAction, props } from '@ngrx/store';
import { LinkedActivityParameters } from '../../../../shared/linked-activity-parameters';

export const loadLinkedActivitiesOverviewFilters = createAction(
  '[LinkedActivitiesOverviewFilter] Load LinkedActivitiesOverviewFilters'
);

export const updateLinkedActivitiesOverviewFilters = createAction(
  '[LinkedActivitiesOverviewFilter] Update Linked Activities Overview Filters',
  props<{
        filterParams: LinkedActivityParameters;
        debounce: boolean
    }>()
);

export const updateLinkedActivitiesOverviewNumberOfFilters = createAction(
  '[LinkedActivitiesOverviewFilter] Update Linked Activities Number Of Filters',
  props<{
        filterParams: LinkedActivityParameters
    }>()
);

export const updateLinkedActivitiesOverviewFiltersPage = createAction(
  '[LinkedActivitiesOverviewFilter] Update Linked Activities Filters page',
  props<{
        resultsPerPage: number;
        page: number;
    }>()
);

export const updateLinkedActivitiesOverviewFiltersSort = createAction(
  '[LinkedActivitiesOverviewFilter] Update Linked Activities Filters Sort',
  props<{
        sortDirection: string;
        sortBy: string
    }>()
);





