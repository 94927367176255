import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class RsMaterialPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy {
  private subscription?: Subscription;

  public constructor(private readonly translateService: TranslateService) {
    super();
    this.getTranslations();
  }

  /** Complete destroy$ subject
   * Once completed it kills the subscibtions using takeUntil(this.destroy$)
   * This is to avoid memory leaks caused when subscriptions aren't killed
   */
  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public getTranslations(): void {
    this.subscription = this.translateService
      .stream([
        'PAGINATOR.ITEMS_PER_PAGE',
        'PAGINATOR.NEXT_PAGE',
        'PAGINATOR.PREVIOUS_PAGE',
        'PAGINATOR.RANGE',
        'PAGINATOR.FIRST_PAGE_LABEL',
        'PAGINATOR.LAST_PAGE_LABEL',
        'RANGE_SEPARATOR_LABEL',
        'PAGE',
      ])
      .subscribe((translation): void => {
        this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
        this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
        this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE_LABEL'];
        this.lastPageLabel = translation['PAGINATOR.LAST_PAGE_LABEL'];
        this.changes.next();
      });
  }

  /** Display pages not range */
  public override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return '';
    }

    length = Math.max(length, 0);

    const currentPage = page;

    const totalPages = Math.ceil(length / pageSize);

    return `${this.translateService.instant('PAGINATOR.PAGE')} ${
      currentPage + 1
    } ${this.translateService.instant(
      'PAGINATOR.RANGE_SEPARATOR_LABEL'
    )} ${totalPages}`;
  };
}
