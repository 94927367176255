import { createAction, props } from '@ngrx/store';
import { ActivityFuelParameters } from '@shared/models/activities/fuel/activity-fuel-parameters';

export const updateActivitiesFuelOverviewFilter = createAction(
  '[Overview] Update ActivitiesFuelOverview Filter',
  props<{ params: ActivityFuelParameters }>()
);

export const updateActivitiesFuelOverviewPaginator = createAction(
  '[Overview] Update ActivitiesFuelOverview Paginator',
  props<{
    resultsPerPage: number;
    page: number;
  }>()
);

export const updateActivitiesFuelOverviewSort = createAction(
  '[Overview] Update ActivitiesFuelOverview Sort',
  props<{
    sortDirection: string;
    sortBy: string;
  }>()
);




