import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storePreferredTyreBrandFilters, updatePreferredTyreBrandOverviewPaginator, updatePreferredTyreBrandSorting } from '@app/leasing-company/preferred-tyre-brand/overview/state/overview.actions';
import { PreferredTyreBrandParameters } from '@shared/models/preferred-tyre-brand/preferred-tyre-brand-parameters';
import { TableOptions } from '@lib/rs-ngx';
import { produce } from 'immer';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewPreferredTyreBrandFeatureKey = 'overview-preferred-tyre-type';

export const sortableColumns = ['vehicleTypeCode'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface PreferredTyreBrandOverviewState {
    tableFilters: PreferredTyreBrandParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export const preferredTyreBrandInitialState: PreferredTyreBrandOverviewState = {
  tableOptions: {
    sort: {
      active: 'vehicleTypeCode',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {
    vehicleType: null,
    tyreTypes: {
      ALL_WEATHER: false,
      SUMMER: false,
      WINTER: false,
    },
    tyreBrand: null
  }
};

export const preferredTyreBrandReducer = createReducer(
  preferredTyreBrandInitialState,

  on(updatePreferredTyreBrandOverviewPaginator, (state, { pageEvent }) =>
    produce(state, draftState => {
      draftState.tableOptions.pageEvent = pageEvent;
    })),
  on(updatePreferredTyreBrandSorting, (state, { sort }) =>
    produce(state, draftState => {
      draftState.tableOptions.sort = sort;
    })),
  on(storePreferredTyreBrandFilters, (state, { tableFilters }) =>
    produce(state, draftState => {
      Object.assign(draftState.tableFilters, tableFilters);
    }))
);

function localStorageSyncReducer(reducer: ActionReducer<PreferredTyreBrandOverviewState>): ActionReducer<PreferredTyreBrandOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_preferred_tyre_type_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const prefTyreBrandMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
