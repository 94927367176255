import { TableOptions } from '@lib/rs-ngx';
import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import {
  storeDisapprovalReasonThresholdFilters,
  updateDisapprovalReasonThresholdOverviewPaginator,
  updateDisapprovalReasonThresholdSorting
} from '@app/leasing-company/disapproval-reason-threshold/overview/state/overview.actions';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  rsLocalStorageMergeReducer
} from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';
import { LeasingContractGroupSummary } from '@shared/models/leasing-contract-group/leasing-contract-group-summary';
import { resetState } from '@core/states/core.actions';

export const overviewDisapprovalReasonThresholdFeatureKey = 'overview-leasing-company-disapproval-reason-threshold';
export const sortableColumns = ['leasingContractGroup'] as const;

export type SortableColumnsType = typeof sortableColumns[number];

export interface DisapprovalReasonThresholdOverviewState {
  tableFilters: DisapprovalReasonThresholdParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export interface DisapprovalReasonThresholdParameters {
  activeContracts: boolean;
  leasingContractGroup?: LeasingContractGroupSummary;
}

export const disapprovalReasonThresholdInitialState = (): DisapprovalReasonThresholdOverviewState => ({
  tableOptions: {
    sort: {
      active: 'leasingContractGroup',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {
    activeContracts: true
  }
});

export const disapprovalReasonThresholdReducer = createReducer(
  disapprovalReasonThresholdInitialState(),

  on(updateDisapprovalReasonThresholdOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent
      }
    };
  }),

  on(updateDisapprovalReasonThresholdSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort
      }
    }
  }),

  on(storeDisapprovalReasonThresholdFilters, (state, { tableFilters }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters
      }
    };
  }),

  on(resetState, (_state) => {
    return disapprovalReasonThresholdInitialState();
  })
);

function localStorageSyncReducer(reducer: ActionReducer<DisapprovalReasonThresholdOverviewState>): ActionReducer<DisapprovalReasonThresholdOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_disapproval_reason_threshold_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const disapprovalReasonThresholdMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
