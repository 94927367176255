import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AllowNavigationGuard {
  public canNavigate = true
}

export const allowNavigationGuard = <T>(): CanDeactivateFn<T> => {
  return (): boolean => {
    const navigationControlService = inject(AllowNavigationGuard);
    return navigationControlService.canNavigate;
  }
};
