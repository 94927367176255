import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { VehicleTypeParameters } from '@shared/models/vehicle/leasing-company/vehicle-type-parameters';
import { storeVehicleTypeFilters, updateVehicleTypeOverviewPaginator, updateVehicleTypeSorting } from '@app/leasing-company/vehicle-types/overview/state/overview.actions';
import { localStorageSync } from 'ngrx-store-localstorage';
import { TableOptions } from '@lib/rs-ngx';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewVehicleTypeFeatureKey = 'overview-vehicle-type';

export const sortableColumns = ['code', 'description'] as const;
export type SortableColumnsType = typeof sortableColumns[number];

export interface VehicleTypeOverviewState {
  tableFilters: VehicleTypeParameters;
  tableOptions: Omit<TableOptions<SortableColumnsType>, 'resultCount'>;
}

export const vehicleTypeInitialState: VehicleTypeOverviewState = {
  tableOptions: {
    sort: {
      active: 'code',
      direction: 'asc'
    },
    pageEvent: {
      length: 0,
      pageIndex: 0,
      pageSize: 10
    }
  },
  tableFilters: {}
};

export const vehicleTypeReducer = createReducer(
  vehicleTypeInitialState,

  on(updateVehicleTypeOverviewPaginator, (state, { pageEvent }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        pageEvent
      }
    };
  }),

  on(updateVehicleTypeSorting, (state, { sort }) => {
    return {
      ...state,
      tableOptions: {
        ...state.tableOptions,
        sort: sort
      }
    }
  }),

  on(storeVehicleTypeFilters, (state, {
    tableFilters
  }) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters
      }
    };
  })
);

function localStorageSyncReducer(reducer: ActionReducer<VehicleTypeOverviewState>): ActionReducer<VehicleTypeOverviewState> {
  return localStorageSync({
    keys: [
      'tableOptions',
      'tableFilters',
    ],
    storageKeySerializer: key => 'leasing_company_vehicle_type_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer,
  })(reducer);
}

export const vehicleTypeMetaReducers: Array<MetaReducer> = [localStorageSyncReducer];
