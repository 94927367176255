import { createAction } from '@ngrx/store';

export const CURRENT_USER_INITIALIZED = createAction('[Core] Current User Initialized');

export const CURRENT_USER_DESTROYED = createAction('[Core] Current User Destroyed');

export const showHttpRequestSuccessMessage = createAction(
  '[Core] Http Request success'
);

export const resetState = createAction(
  '[Core] clear State action'
);
