import { createAction, props } from '@ngrx/store';
import {
  DisapprovalReasonParameters
} from '@shared/models/disapproval-reason/leasing-company/disapproval-reason-parameters';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import { SortableColumnsType } from '@app/leasing-company/disapproval-reason/overview/overview/state/overview.reducer';

export const updateDisapprovalReasonOverviewPaginator = createAction(
  '[LC Disapproval Reason Overview] Update disapproval reason paginator',
  props<{ pageEvent: PageEvent }>(),
);

export const updateDisapprovalReasonSorting = createAction(
  '[LC Disapproval Reason Overview] Update disapproval reason sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>(),
);

export const storeDisapprovalReasonFilters = createAction(
  '[LC Disapproval Reason Overview] Store disapproval reason filters',
  props<{
    tableFilters: DisapprovalReasonParameters
  }>()
);
