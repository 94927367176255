import { createAction, props } from '@ngrx/store';
import { VehicleTypeParameters } from '@shared/models/vehicle/leasing-company/vehicle-type-parameters';
import { PageEvent } from '@angular/material/paginator';
import { TableOptions } from '@lib/rs-ngx';
import { SortableColumnsType } from '@app/leasing-company/vehicle-types/overview/state/overview.reducer';

export const updateVehicleTypeOverviewPaginator = createAction(
  '[LC Vehicle Type Overview] Update vehicle type paginator',
  props<{ pageEvent: PageEvent }>()
);

export const updateVehicleTypeSorting = createAction(
  '[LC Vehicle Type Overview] Update vehicle type sorting',
  props<{ sort: TableOptions<SortableColumnsType>['sort'] }>(),
);

export const storeVehicleTypeFilters = createAction(
  '[LC Vehicle Type Overview] Store vehicle type filters',
  props<{
      tableFilters: VehicleTypeParameters
  }>()
);
