import { Component, CUSTOM_ELEMENTS_SCHEMA, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-rs-number-selection',
  templateUrl: './rs-number-selection.component.html',
  styleUrls: ['./rs-number-selection.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    NgForOf
  ],
})
export class RsNumberSelectionComponent {

  protected readonly users: Signal<User[]>;

  constructor(
    route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    this.users = toSignal(route.data.pipe(map(data => data['users'])), { initialValue: [] });
  }

  public selectRsUser(user: User): void {
    this.userService.setCurrentUser(user);
    this.router.navigateByUrl(this.userService.getDefaultRedirectURL());
  }
}
